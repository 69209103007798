import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { User } from '../models';
import { ToastService } from '../modules/toast-global/toast.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	apiRoot: string = environment.apiUrl;

	private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isAuthenticated());
	private loggedUser: BehaviorSubject<User> = new BehaviorSubject<User>(this.getUserDetails());
	authenticationState = this.loggedIn.asObservable();
	loggedUserState = this.loggedUser.asObservable();

	constructor(
		private http: HttpClient,
		private jwtHelper: JwtHelperService,
		public toastService: ToastService,
		private router: Router
	) { }

	login(credentials?: any, role?: any) {
		return this.http.post('authentication/login', credentials)
			.pipe(
				map((response: any) => {
					//const response = data.data;
					/*if(response.data.role === role) {
						console.log('role', role)
						this.toastService.show('Login Success', { classname: 'bg-success' });
						//return false;
						this.loggedIn.next(true);
						this.loggedUser.next(Object.assign({}, response.data));
						localStorage.setItem('token', JSON.stringify(data.token));
						localStorage.setItem('user', JSON.stringify(response.data));
						window.location.href = '/';
					} else {
						this.toastService.show('Your login fail.', { classname: 'bg-danger' });
					}*/
					return response;
				}), catchError(this.handleErrors)
			);
	}

	oauth(params: any, params2: any) {
		return this.http.request("POST", `authentication/oauth`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.handleErrors));
	}

	register(credentials: any) {
		return this.http.post('authentication/register', credentials)
		.pipe(map((response: any) => {
			return response;
		}),catchError(this.handleErrors));
	}

	logout(credentials = null) {
		this.router.navigate(['/']);
		return this.http.post('authentication/logout', credentials)
		.pipe(map((response: any) => {
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			this.loggedUser.next(new User);
			this.loggedIn.next(false);
			return response;
		}),catchError(this.handleErrors));
	}

	forgotPassword(credentials: any) {
		return this.http.post('authentication/forgot-password', credentials)
		.pipe(map((response: any) => {
			return response;
		}),catchError(this.handleErrors));
	}

	resetPassword(credentials: any, params: any) {
		return this.http.post('authentication/reset-password', credentials, {params: params})
		.pipe(map((response: any) => {
			return response;
		}),catchError(this.handleErrors));
	}

	emailVerification(credentials = null, params:any) {
		return this.http.post('authentication/email-verification', credentials, {params: params})
		.pipe(map((response: any) => {
			return response;
		}),catchError(this.handleErrors));
	}

	handleErrors(error: HttpErrorResponse) {
		return throwError(error || "Server Error");
	}

	getAuthorizationToken() {
		const token: any = localStorage.getItem('token');
		//console.log('token', token);
		return (token !== null) ? JSON.parse(token) : null;
	}

	isAuthenticated() {
		return !this.jwtHelper.isTokenExpired(this.getAuthorizationToken());
	}

	expirationTokenDate() {
		return this.jwtHelper.getTokenExpirationDate(this.getAuthorizationToken());
	}

	decode() {
		return this.jwtHelper.decodeToken(this.getAuthorizationToken());
	}

	isTokenExpired() {
		return this.jwtHelper.isTokenExpired(this.getAuthorizationToken());
	}

	userRole() {
		const user = localStorage.getItem('user');
		return (user !== null) ? JSON.parse(user).role : null;
	}

	getUserDetails(): any {
		const user = localStorage.getItem('user');
		return (user !== null) ? JSON.parse(user) : null;
	}
	
}
