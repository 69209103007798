import { Action } from '@ngrx/store';
import { Location } from 'src/app/models';

export enum LocationActionTypes {
    LOCATION_PARAMS = '[LOCATION_PARAMS] Location Params',
    LOCATION_LOCATIONS = '[LOCATION_LOCATIONS] Location List',
    LOCATION_MORE = '[LOCATION_MORE] Location More',
    LOCATION_ADD = '[LOCATION_ADD] Location Add',
    LOCATION_UPDATE = '[LOCATION_UPDATE] Location Update',
    LOCATION_DELETE = '[LOCATION_DELETE] Location Delete',
    LOCATION_VIEW = '[LOCATION_VIEW] Location View',
    LOCATION_FAILURE = '[LOCATION_FAILURE] failed',
}

export class Params implements Action {
    readonly type = LocationActionTypes.LOCATION_PARAMS;
    constructor(public method: string, public params?: any, public params2?: any, public key?: string ) { }
}

export class Locations implements Action {
    readonly type = LocationActionTypes.LOCATION_LOCATIONS;
    constructor(public locations: any) { }
}

export class More implements Action {
    readonly type = LocationActionTypes.LOCATION_MORE;
    constructor(public more: Location[]) { }
}

export class Add implements Action {
    readonly type = LocationActionTypes.LOCATION_ADD;
    constructor(public add: Location) { }
}

export class Update implements Action {
    readonly type = LocationActionTypes.LOCATION_UPDATE;
    constructor(public update: Location) { }
}

export class Delete implements Action {
    readonly type = LocationActionTypes.LOCATION_DELETE;
    constructor(public remove: any) { }
}

export class View implements Action {
    readonly type = LocationActionTypes.LOCATION_VIEW;
    constructor(public view: any) { }
}

export class failure implements Action {
    readonly type = LocationActionTypes.LOCATION_FAILURE;
    constructor(public error: any) { }
}

export type LocationAction = Params | Locations | More | Add | Update | Delete | View | failure;