<div class="booking-box flex-wrap">
    <div class="btndiv" *ngIf="item?.user_id === gs.identity.id && item?.status === status.ORDER_PENDING">
        <button class="deletecard2" (click)="remove()"><i class="far fa-trash-alt" aria-hidden="true"></i></button>
        <a class="deletecard2" routerLink="/coach/booking/update/{{item?.id}}"><i class="fas fa-pencil-alt" aria-hidden="true"></i></a>
    </div>
    <div class="profile-group">
        <div class="photo">
            <img [src]="user?.avatar" alt="" class="img-fluid rounded-circle" />
        </div>
        <div class="user-details">
            <h5>
                <a routerLink="/{{(gs.identity.role === role.User) ? 'user' : 'coach'}}/booking/{{item?.id}}">{{item?.title }}</a>
            </h5>

            <h6 *ngIf="gs.identity.role === role.Coach">{{ user?.name }}</h6>
            <h6 *ngIf="gs.identity.role === role.User">
                <a routerLink="/coach/view/{{user?.id}}">{{ user?.name }}</a>
            </h6>
            <div class="rating" *ngIf="gs.identity.role === role.User">
                <ngb-rating [max]="5" [readonly]="true" [rate]="user?.avg_rating"></ngb-rating>
                <small>({{user?.avg_rating}}) {{user?.count_rating}} Ratings</small>
            </div>
            <div class="location">
                <img src="/assets/images/svg/map-pin-2.svg" alt="" />
                <span>{{user?.location ? user?.location?.location : '--'}}</span>
            </div>
            <!-- <div class="location">
                <b>Booking Title:</b> {{item?.title}}
            </div> -->
        </div>
    </div>
    <div class="time-group">
        <div class="row">
            <div class="col-sm-6">
                <span class="date">Date : </span>
                <span class="text-success">
                    {{moment($any(order?.start_time) * 1000).format('DD-MM-YYYY') }}
                </span>
            </div>
            <div class="col-sm-6">
                <span class="duration">Duration : </span>
                <span class="text-success">{{order?.slot}} Min</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <span class="date">Time : </span>
                <span class="text-success">{{moment($any(order?.start_time) * 1000).format('HH:mm') }}</span>
            </div>
            <div class="col-sm-6">
                <span class="duration">Total Cost :</span>
                <span class="text-success">{{item?.total | currency}}</span>
            </div>
            
        </div>
    </div>
    <div class="cost">
        <div>
            <div class="w-100">
                <span class="type">Class Type : </span>
                <span class="text-success">
                    {{(item?.is_public === 0) ? $any(item?.no_recipients) <= 2  ? 'One on One' : 'Small Group' : 'Group Class' }}
                </span>
            </div>
            <div class="w-100 pt-3">
                <span class="duration pr-3" style="width: 110px;">Participants :</span>
                <span class="text-success"> {{$any(item?.no_recipients) - 1}} / {{ $any(item?.recipientIds?.length) - 1 }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="status.ORDER_CANCELLED === item?.status" class="pt-3">
        <b>Reason : </b> {{item?.comment}}
    </div>
    <!-- <span class="badge bg-danger text-white" *ngIf="">
        Expride
    </span> -->
    <a class="btn-view" routerLink="/{{(gs.identity.role === role.User) ? 'user' : 'coach'}}/booking/{{item?.id}}">
        <i class="far fa-arrow-alt-circle-right"></i>
    </a>
</div>

<!-- {{ $any(order?.start_time) * 1000 | date: 'full' : 'GTM' }} -->

<!-- <a routerLink="/{{(gs.identity.role === role.User) ? 'user' : 'coach'}}/booking/{{item?.id}}">View Booking</a> -->