import { Action } from '@ngrx/store';

export enum UserActionTypes {
    USER_PROFILE = '[USER_PROFILE] User Profile',
    USER_IS_AVAILABLE = '[USER_IS_AVAILABLE] User Profile Availability',
    USER_PROFILE_SUCCESS = '[USER_PROFILE_SUCCESS] User Profile Success',
    TRANSACTIONS_PARAMS = '[TRANSACTIONS_PARAMS] User Transactions Params',
    TRANSACTIONS = '[TRANSACTIONS] User Transactions',
    USER_FAILURE = '[USER_FAILURE] failed',
}

export class Profile implements Action {
    readonly type = UserActionTypes.USER_PROFILE;
    //constructor(public options?: { method: string, params?: any, params2?: any }) { }
    constructor(public method?: string | 'POST', public params?: any, public key?: string | 'profile') { }
}


export class IsAvailable implements Action {
    readonly type = UserActionTypes.USER_IS_AVAILABLE;
    constructor(public params?: any) { }
}

export class ProfileSuccess implements Action {
    readonly type = UserActionTypes.USER_PROFILE_SUCCESS;
    constructor(public item: any) { }
}

export class TransactionsParams implements Action {
    readonly type = UserActionTypes.TRANSACTIONS_PARAMS;
    constructor( public params?: any) { }
}

export class Transactions implements Action {
    readonly type = UserActionTypes.TRANSACTIONS;
    constructor( public list: any) { }
}

export class failure implements Action {
    readonly type = UserActionTypes.USER_FAILURE;
    constructor(public error: any) { }
}


export type UserAction = Profile | IsAvailable | ProfileSuccess | TransactionsParams | Transactions | failure;