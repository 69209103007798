import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ConfirmDialogService } from './modules';
import { AuthService, GlobalService, SocketService } from './services';
import { DefaultAction, UserAction } from './store/action';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	moment = moment;
	isLoggedIn$!: Observable<boolean>;
	title = 'web';
	private socket: any;

	currentDate = new Date();

	constructor(
		public gs: GlobalService,
		private authService: AuthService,
		private swUpdate: SwUpdate,
        public confirmDialog: ConfirmDialogService,
		//private chatService: SocketService,

	) {
		//this.socket = io(environment.SOCKET_URL);
	}
	
	ngOnInit(): void {
		this.isLoggedIn$ = this.authService.authenticationState;
		this.gs.store.dispatch(new DefaultAction.CategoriesParams());
		this.isLoggedIn$.pipe(debounceTime(100)).subscribe((data: any) => {
			if (data) {
				//console.log('datadatadata', data);
				this.gs.store.dispatch(new UserAction.Profile("GET", null, 'load_profile'));
			}
		});

		if (this.swUpdate.isEnabled) {
			this.swUpdate.available.subscribe(() => {
				this.confirmDialog.confirmThis("New version available. Load New Version?", () => {
					window.location.reload();
				}, () => {
					console.log('No clicked');
				});
			});
		}

		/*this.chatService.getMessages().subscribe(data => {
			console.log('data123', data);
		});

		this.socket.on('message', (response: any) => {
			let data = JSON.parse(response);
			console.log('datadatadata', data);
		});*/

	}
	sendMsg() {
		//this.chatService.sendMessage('this.newMessage');
	}
}
