import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators';
import { GlobalService, ItemService } from 'src/app/services';
import { ItemAction } from '../action';

@Injectable()
export class ItemEffects {
    constructor(
        private actions$: Actions,
        private itemService: ItemService,
        private gs: GlobalService
    ) { }

    loadStaff$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ItemAction.ItemActionTypes.ITEM_STAFF_PARAMS),
            debounceTime(3000),
            mergeMap((params: ItemAction.params) =>
                this.itemService.profile('GET', null, params.params).pipe(
                    map((item: any) => new ItemAction.staffs(item.data)),
                    catchError(error => {
                        this.gs.handleErrors(error);
					    return [new ItemAction.failure(error)]
                    })
                )
            )
        )
    );

}