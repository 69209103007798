import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as cmsReducer from '../reducer/cms.reducer';

// Lookup the 'Joke' feature state managed by NgRx
const CmsState = createFeatureSelector<cmsReducer.State>(
    cmsReducer.featureKey
);

export const selectSectorsList = createSelector(
    CmsState,
    state => state.sectors
);

export const selectPracticesList = createSelector(
    CmsState,
    state => state.practices
);

export const selectPagesList = createSelector(
    CmsState,
    state => state.pages
);

export const selectBlogsList = createSelector(
    CmsState,
    state => state.blogs && state.blogs.items
);

export const selectBlogPagination = createSelector(
    CmsState,
    state => state.blogs && state.blogs.pagination
);

export const selectBlogLoading = createSelector(
    CmsState,
    state => state.blogLoading
);

export const News = createSelector(
    CmsState,
    state => state.news && state.news.items
);

export const NewsPagination = createSelector(
    CmsState,
    state => state.news && state.news.pagination
);

export const Events = createSelector(
    CmsState,
    state => state.events && state.events.items
);

export const EventsPagination = createSelector(
    CmsState,
    state => state.events && state.events.pagination
);

export const Articles = createSelector(
    CmsState,
    state => state.articles && state.articles.items
);

export const ArticlesPagination = createSelector(
    CmsState,
    state => state.articles && state.articles.pagination
);

export const Leaderships = createSelector(
    CmsState,
    state => state.leaderships && state.leaderships.items
);

export const LeadershipsPagination = createSelector(
    CmsState,
    state => state.leaderships && state.leaderships.pagination
);

export const Educationals = createSelector(
    CmsState,
    state => state.educationals && state.educationals.items
);

export const EducationalsPagination = createSelector(
    CmsState,
    state => state.educationals && state.educationals.pagination
);

export const Researchs = createSelector(
    CmsState,
    state => state.researchs && state.researchs.items
);

export const ResearchsPagination = createSelector(
    CmsState,
    state => state.researchs && state.researchs.pagination
);

export const Testimonials = createSelector(
    CmsState,
    state => state.testimonials && state.testimonials.items
);

export const TestimonialsPagination = createSelector(
    CmsState,
    state => state.testimonials && state.testimonials.pagination
);

export const Faqs = createSelector(
    CmsState,
    state => state.faqs && state.faqs.items
);

export const FaqsPagination = createSelector(
    CmsState,
    state => state.faqs && state.faqs.pagination
);

export const selectCmsError = createSelector(
    CmsState,
    state => state.error
);

export const selectCmsLoading = createSelector(
    CmsState,
    state => state.loading
);

export const selectAppComponentViewModel = createSelector(
    selectSectorsList,
    selectCmsError,
    selectCmsLoading,
    (sectors, error, loading) => ({
        sectors,
        error,
        loading
    })
);