import { Pagination } from "./Pagination";

export class ApiResponse {
    code!: number;
    message!: string;
    status!: number;
    data?: ApiResponseData;
}

export class ApiResponseData {
    model!: any;
    items!: any;
    pagination!: Pagination
}