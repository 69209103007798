<app-header></app-header>
<section class="all-content">
    <div class="not-found my-4">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="404-page text-center">
                        <h1>Page Not Found</h1>
                        <section class="error-container">
                            <span class="four"><span class="screen-reader-text">4</span></span>
                            <span class="zero"><span class="screen-reader-text">0</span></span>
                            <span class="four"><span class="screen-reader-text">4</span></span>
                        </section>
                        <p>The above error occurred while the Web server was processing your request.</p>
                        <p>Please contact us if you think this is a server error. Thank you.</p>
                        <div class="link-container">
                            <a routerLink="/" class="btn btn-primary">Visit the original site</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>