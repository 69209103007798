import { Action, createReducer, on } from '@ngrx/store';
import { Countries } from 'src/app/models/Countries';
import { States } from 'src/app/models/States';
import { DefaultAction, DefaultActionTypes } from '../action/default.action'; 


export const featureKey = 'default';

export interface State {
	countries: Countries[] | null,
	states: States[] | null,
    categories: any,
    config: any,
    platforms: any,
	loading: boolean,
	error: any
}

export const initialState: State = {
	countries: null,
	states:null,
    categories: null,
    config: null,
    platforms: null,
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: DefaultAction | any): State {

	switch (action.type) {

		case DefaultActionTypes.DEFAULT_CATEGORIES_PARAMS:
			return {
				...state,
				loading: true
			}
       
        case DefaultActionTypes.DEFAULT_COUNTRIES:
			return {
				...state,
                countries: action.countries,
				loading: false
			}

		case DefaultActionTypes.DEFAULT_STATES:
			return {
				...state,
				states: action.states,
				loading: false
			}

		case DefaultActionTypes.DEFAULT_CATEGORIES:
			return {
				...state,
				categories: action.categories,
				loading: false
			}

        case DefaultActionTypes.DEFAULT_CONFIG:
			return {
				...state,
                config: action.config,
				loading: false,
			}
			
        case DefaultActionTypes.DEFAULT_FAILURE:
			return {
				...state,
				loading: false,
                error: action.error,
			}
		default:
			return state;
	}
};