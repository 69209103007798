import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
// import { AuthService } from 'src/app/services';
import { AuthService, GlobalService } from 'src/app/services';
import { User } from 'src/app/models';
import { CategoriesEnum, Role } from 'src/app/enums';
import { ConfirmDialogService } from 'src/app/modules';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isLoggedIn: Observable<boolean> = this.authService.authenticationState;
  user: Observable<User> = this.gs.store.select(state => state.auth.auth);
  role = Role;
  constructor(
    public authService: AuthService,
    public gs: GlobalService,
    private confirmDialog: ConfirmDialogService,
  ) { }

  ngOnInit(): void {
  }
  alert() {
		//alert('Coming Soon...')
		this.confirmDialog.confirmAlert("Coming Soon..", () => {

		}, () => {
			console.log('No clicked');
		});
	}
    currentYearLong(): number {
    return new Date().getFullYear();
    }

}
