import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State as AuthState } from '../reducer/auth.reducer';

const getAuthFeatureState = createFeatureSelector<AuthState>('auth');

export const getUser = createSelector(
    getAuthFeatureState,
    state => state.auth
)

export const loading = createSelector(
    getAuthFeatureState,
    state => state.loading
)

export const loadingAuth = createSelector(
    getAuthFeatureState,
    state => state.loadingAuth
)

export const failure = createSelector(
    getAuthFeatureState,
    state => state.error
)