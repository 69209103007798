import {User} from './User';
import {Photo} from './Photo';
import { Order } from './Order';

export class Product {
  userCatalogPrices!: userCatalogPrices[];
  userCatalogOptions!: userCatalogOptions[];
  currentOffer!: Order;
  offers!: number;
  location: any;
  media!: any;
  is_wishlist!: boolean;
  id!: number;
  type!: string;
  user_type!: string;
  user_id!: number;
  category_id!: number;
  sub_category_id!: number;
  cover_id!: number;
  brand_id!: number;
  cause_id!: number;
  sku!: string;
  quantity!: number;
  minimum!: number;
  notify_minimum!: number;
  download_link!: string;
  title!: string;
  description!: string;
  specification!: string;
  price!: number;
  discount_price!: number;
  reserve_price!: number;
  dated!: string;
  approx_time!: string;
  weight!: string;
  length!: string;
  height!: string;
  width!: string;
  length_unit!: string;
  weight_unit!: string;
  is_subtract!: string;
  tax_id!: string;
  shipping_rate_id!: string;
  in_stock!: string;
  location_id!: string;
  orders!: string;
  ratings!: number;
  avg_rating!: number;
  viewed!: number;
  is_publish!: string;
  is_featured!: number;
  featured_price!: string;
  featured_start_time!: any;
  featured_end_time!: any;
  auction_start_time!: any;
  auction_end_time!: any;
  is_available!: number;
  status!: number;
  order_by!: number;
  created_at!: number;
  updated_at!: number;
  category!: string;
  cover!: Photo;
  shippingRate!: string;
  wishlist!: boolean;
  user!: User;
  fieldModel!: any
}

export class userCatalogOptions {
  id!: number;
  item_id!: number;
  field_id!: number;
  name!: string;
  value!: string;
  price!: number;
  quantity!: number;
  field!: userCatalogField
}

export class userCatalogField {
  field_id!: number;
  user_id!: number;
  field!: string;
  type!: string;
  name!: string;
  label!: string
}

export class userCatalogPrices {

}
