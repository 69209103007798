import { createAction, props, Action } from '@ngrx/store';
import { User } from 'src/app/models';

export enum ItemActionTypes {
    ITEM_STAFFS = '[ITEM_STAFF] Staff List',
    ITEM_STAFF_PARAMS = '[ITEM_STAFF_PARAMS] Staff Params',
    ITEM_COURTS = '[ITEM_COURTS] Courts',
    ITEM_FAILURE = '[ITEM_FAILURE] failed',
}

export class params implements Action {
    readonly type = ItemActionTypes.ITEM_STAFF_PARAMS;
    constructor(public params: any) { }
}

export class staffs implements Action {
    readonly type = ItemActionTypes.ITEM_STAFFS;
    constructor(public staffs: User[]) { }
}

export class courts implements Action {
    readonly type = ItemActionTypes.ITEM_COURTS;
    constructor(public courts: any) { }
}

export class failure implements Action {
    readonly type = ItemActionTypes.ITEM_FAILURE;
    constructor(public error: any) { }
}

export type ItemAction = params | staffs | courts | failure;