import { User } from "./User";

export class Availability {
    id!: number;
    user_id!: number;
    week_day!: string;
    start_time!: any;
    end_time!: any;
    status!: number;
    created_at!: any;
    updated_at!: any;
    user!: User;
    blank_id!: number
}