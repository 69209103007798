import { Action, createReducer, on } from '@ngrx/store';
import { Item } from 'src/app/models/Item';
import * as CmsActions from '../action/cms.actions';

export interface State {
    sectors: Item[] | null;
    practices: Item[] | null;
    pages: Item[] | null;
    blogs: any;
    news: any;
    events: any;
    articles: any;
    leaderships: any;
    educationals: any;
    faqs: any;
    researchs: any;
    testimonials: any;
    blogLoading: boolean;
    loading: boolean;
    error: string;
}

export const featureKey = 'cms';

export const initialState: State = {
    sectors: null,
    practices: null,
    pages: null,
    blogs: null,
    news: null,
    events: null,
    articles: null,
    leaderships: null,
    educationals: null,
    faqs: null,
    researchs: null,
    testimonials: null,
    blogLoading: false,
    loading: false,
    error: ''
};

const cmsReducer = createReducer(
    initialState,
    on(
        CmsActions.cmsSectorsInitialized,
        CmsActions.cmsPracticesInitialized,
        CmsActions.cmsPagesInitialized,
        state => ({ ...state, loading: true, error: '' })
    ),
    on(
        CmsActions.cmsBlogPodcastInitialized,
        CmsActions.NewsInitialized,
        CmsActions.EventsInitialized,
        CmsActions.ArticlesInitialized,
        CmsActions.LeadershipsInitialized,
        CmsActions.EducationalsInitialized,
        CmsActions.ResearchsInitialized,
        CmsActions.TestimonialsInitialized,
        CmsActions.FaqsInitialized,
        state => ({ ...state, blogLoading: true, error: '' })
    ),
    on(
        CmsActions.cmsSectorsSuccess,
        (state, { sectors }) => ({
            ...state,
            sectors,
            loading: false
        })
    ),
    on(
        CmsActions.cmsPracticeAreas,
        (state, { practices }) => ({
            ...state,
            practices,
            loading: false
        })
    ),
    on(
        CmsActions.cmsPages,
        (state, { pages }) => ({
            ...state,
            pages,
            loading: false
        })
    ),
    on(
        CmsActions.cmsBlogPodcast,
        (state, { blogs }) => ({
            ...state,
            blogs,
            blogLoading: false
        })
    ),
    on(
        CmsActions.News,
        (state, { news }) => ({
            ...state,
            news,
            blogLoading: false
        })
    ),
    on(
        CmsActions.Events,
        (state, { events }) => ({
            ...state,
            events,
            blogLoading: false
        })
    ),
    on(
        CmsActions.Articles,
        (state, { articles }) => ({
            ...state,
            articles,
            blogLoading: false
        })
    ),
    on(
        CmsActions.Leaderships,
        (state, { leaderships }) => ({
            ...state,
            leaderships,
            blogLoading: false
        })
    ),
    on(
        CmsActions.Educationals,
        (state, { educationals }) => ({
            ...state,
            educationals,
            blogLoading: false
        })
    ),
    on(
        CmsActions.Faqs,
        (state, { faqs }) => ({
            ...state,
            faqs,
            blogLoading: false
        })
    ),
    on(
        CmsActions.Researchs,
        (state, { researchs }) => ({
            ...state,
            researchs,
            blogLoading: false
        })
    ),
    on(
        CmsActions.Testimonials,
        (state, { testimonials }) => ({
            ...state,
            testimonials,
            blogLoading: false
        })
    ),
    on(
        CmsActions.loadAllFailed,
        (state, { error }) => ({
            ...state,
            error,
            loading: false
        })
    )
)

export function reducer(state: State | undefined, action: Action) {
    return cmsReducer(state, action);
}