import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from './app-routing.module';
import { reducers, metaReducers } from './reducers';
import { environment } from '../environments/environment';
import { LayoutsModule } from './layouts/layouts.module';
import { SharedModule } from './shared/shared.module';
import { JwtModule } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects, CmsEffects, DefaultEffects, UserEffects } from './store/effects';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { httpInterceptorProviders } from './helpers';
import { ConfirmDialogModule, CoreModule, LaddaModule, ToastGlobalModule } from './modules';

import * as moment from 'moment-timezone';
moment.tz.setDefault('Europe/Brussels');


import { AppComponent } from './app.component';


export function tokenGetter() {
	return localStorage.getItem('token');
};

const config: SocketIoConfig = { url: environment.SOCKET_URL };

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		NgbModule,
		LayoutsModule,
		SharedModule,
		CoreModule,
		LaddaModule,
		ToastGlobalModule,
		ConfirmDialogModule,
		JwtModule.forRoot({
			config: {
				tokenGetter: tokenGetter,
			}
		}),
		StoreModule.forRoot(reducers, { metaReducers }), !environment.production ? StoreDevtoolsModule.instrument() : [],
		EffectsModule.forRoot([CmsEffects, DefaultEffects,AuthEffects,UserEffects]),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
		SocketIoModule.forRoot(config),
	],
	providers: [httpInterceptorProviders, {		
		provide: DEFAULT_CURRENCY_CODE,
		useValue: 'EUR'		  
	}],
	bootstrap: [AppComponent]
})
export class AppModule { }
