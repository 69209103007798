<aside id="Left-Side" *ngIf="userObservable | async as user">
    <div class="userdatail">
        <!-- <div class="u-image"><img src="{{user?.avatar}}" alt="" width="100" /></div>
        <div class="u-name">{{user?.name}}</div> -->
        <app-profile-avatar></app-profile-avatar> 

        <h5 *ngIf="user" class="u-name">
            {{user?.name}} 
            <span *ngIf="user.role === role.Coach">
                <i *ngIf="user.is_verified === UserVerified.VERIFIED" ngbTooltip="Verified" class="fas fa-check-circle"></i>
                <i *ngIf="user.is_verified === UserVerified.PENDING" ngbTooltip="Pending" class="fas fa-times-circle"></i>
                <i *ngIf="user.is_verified === UserVerified.INSUFFICIENT" ngbTooltip="Insufficient Information" class="fas fa-times-circle"></i>
                <i *ngIf="user.is_verified === UserVerified.SUBMITTED_FOR_VERIFY" ngbTooltip="Submitted For Verify" class="fas fa-times-circle"></i>
            </span>
            <label *ngIf="user.role === role.Coach" class="switch" ngbTooltip="Availability {{ ($any((userObservable | async)?.userProfile?.is_availability) === 1) ? 'On' : 'Off' }}">
                <input type="checkbox" [checked]="(userObservable | async)?.userProfile?.is_availability" (change)="handleAvailability($event)" [value]="(userObservable | async)?.userProfile?.is_availability">
                <span class="slider round"></span>
            </label>
        </h5>
        
    </div>    
    
    <ul class="aside-menu" *ngIf="user">
        <ng-container *ngIf="user.role === role.User">
            <li>
                <a [routerLinkActive]="['active']" routerLink="/user/dashboard">
                    <div class="img black"><img src="/assets/images/svg/dashboard-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/dashboard-nor.svg" alt="" /></div>
                    <span class="name">Dashboard</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="['active']" routerLink="/user/booking">
                    <div class="img black"><img src="/assets/images/svg/my-booking-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/my-booking-nor.svg" alt="" /></div>
                    <span class="name">My Bookings</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="['active']" routerLink="/user/profile">
                    <div class="img black"><img src="/assets/images/svg/profile-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/profile-nor.svg" alt="" /></div>
                    <span class="name">Profile</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="['active']" routerLink="/user/transactions">
                    <div class="img black"><img src="/assets/images/svg/transactions-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/transactions-nor.svg" alt="" /></div>
                    <span class="name">Transactions</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="['active']" routerLink="/user/notifications">
                    <div class="img black"><img src="/assets/images/svg/notifications-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/notifications-nor.svg" alt="" /></div>
                    <span class="name">Notifications</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="['active']" routerLink="/address/list">
                    <div class="img black"><img src="/assets/images/svg/address-hor.png" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/address-nor.png" alt="" /></div>
                    <span class="name">Address</span>
                </a>
            </li>
        </ng-container>
        <!-- && user?.is_verified === 6 -->
        
       <ng-container *ngIf="user.role === role.Coach">
            <li>
                <a [routerLinkActive]="['active']" routerLink="/coach/dashboard">
                    <div class="img black"><img src="/assets/images/svg/dashboard-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/dashboard-nor.svg" alt="" /></div>
                    <span class="name">Dashboard</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="['active']" routerLink="/coach/classes">
                    <div class="img black"><img src="/assets/images/svg/classes-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/classes-nor.svg" alt="" /></div>
                    <span class="name">Classes</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="['active']" routerLink="/coach/booking">
                    <div class="img black"><img src="/assets/images/svg/my-booking-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/my-booking-nor.svg" alt="" /></div>
                    <span class="name">My Bookings</span>
                </a>
            </li>
            <!-- <li>
                <a [routerLinkActive]="['active']" routerLink="/coach/booking/public/creare">
                    <div class="img black"><img src="/assets/images/svg/my-booking-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/my-booking-nor.svg" alt="" /></div>
                    <span class="name">Create Group Booking</span>
                </a>
            </li> -->
            
            <li>
                <a [routerLinkActive]="['active']" routerLink="/coach/profile">
                    <div class="img black"><img src="/assets/images/svg/profile-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/profile-nor.svg" alt="" /></div>
                    <span class="name">Profile</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="['active']" routerLink="/coach/transactions">
                    <div class="img black"><img src="/assets/images/svg/transactions-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/transactions-nor.svg" alt="" /></div>
                    <span class="name">Transactions</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="['active']" routerLink="/coach/notifications">
                    <div class="img black"><img src="/assets/images/svg/notifications-hor.svg" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/notifications-nor.svg" alt="" /></div>
                    <span class="name">Notifications</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="['active']" routerLink="/address/list">
                    <div class="img black"><img src="/assets/images/svg/address-hor.png" alt="" /></div>
                    <div class="img white"><img src="/assets/images/svg/address-nor.png" alt="" /></div>
                    <span class="name">Address</span>
                </a>
            </li>
        </ng-container>

        
        <li>
            <a routerLink="/" (click)="logout()">
                <div class="img black"><img src="/assets/images/svg/logout-hor.svg" alt="" /></div>
                <div class="img white"><img src="/assets/images/svg/logout-nor.svg" alt="" /></div>
                <span class="name">Logout</span>
            </a>
        </li>
    </ul>
</aside>
<div class="clearfix"></div>