export enum ProductType {
	AUCTION = "auction",
	PRODUCTS = "products",
}

export enum SectionsEnum {
	Motors = 1,
	RealEstate = 2,
	Electronics = 3,
	General = 4,
	BankDetails = 5,
}

export enum CategoriesEnum {
	Motors = 3,
	RealEstate = 4,
	Electronics = 5,
	General = 6,
}


export enum UserVerified {
	ACTIVE = 10,
	UNVERIFIED = 0,
	PENDING = 1,
	VERIFIED = 2,
	INSUFFICIENT = 3,
	SUBMITTED_FOR_VERIFY = 4,
	REQUESTED_FOR_SURVEY = 5,
	APPROVED_FOR_SURVEY = 6,
}

export enum BookingSlot {
	return
}
