import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { AuthReducer, UploadReducer, CmsReducer, ItemReducer, DefaultReducer, UserReducer, ExperienceReducer, EducationsReducer, CertificationReducer, ExpertiseReducer, AvailabilityReducer, NotificationsReducer, ProductsReducer, LocationsReducer, OrderReducer } from '../store/reducer'; 

export interface State {
  [AuthReducer.featureKey]: AuthReducer.State,
  [UploadReducer.featureKey]: UploadReducer.State,
  [CmsReducer.featureKey]: CmsReducer.State,
  [ItemReducer.featureKey]: ItemReducer.State,
  [DefaultReducer.featureKey]: DefaultReducer.State,
  [ExperienceReducer.featureKey]: ExperienceReducer.State,
  [UserReducer.featureKey]: UserReducer.State,
  [EducationsReducer.featureKey]: EducationsReducer.State,
  [CertificationReducer.featureKey]: CertificationReducer.State,
  [ExpertiseReducer.featureKey]: ExpertiseReducer.State,
  [AvailabilityReducer.featureKey]: AvailabilityReducer.State,
  [NotificationsReducer.featureKey]: NotificationsReducer.State,
  [ProductsReducer.featureKey]: ProductsReducer.State,
  [LocationsReducer.featureKey]: LocationsReducer.State,
  [OrderReducer.featureKey]: OrderReducer.State,
}

export const reducers: ActionReducerMap<State> = {
  [AuthReducer.featureKey]: AuthReducer.reducer,
  [UploadReducer.featureKey]: UploadReducer.reducer,
  [CmsReducer.featureKey]: CmsReducer.reducer,
  [ItemReducer.featureKey]: ItemReducer.reducer,
  [DefaultReducer.featureKey]: DefaultReducer.reducer,
  [UserReducer.featureKey]: UserReducer.reducer,
  [ExperienceReducer.featureKey]: ExperienceReducer.reducer,
  [EducationsReducer.featureKey]: EducationsReducer.reducer,
  [CertificationReducer.featureKey]: CertificationReducer.reducer,
  [ExpertiseReducer.featureKey]: ExpertiseReducer.reducer,
  [AvailabilityReducer.featureKey]: AvailabilityReducer.reducer,
  [NotificationsReducer.featureKey]: NotificationsReducer.reducer,
  [ProductsReducer.featureKey]: ProductsReducer.reducer,
  [LocationsReducer.featureKey]: LocationsReducer.reducer,
  [OrderReducer.featureKey]: OrderReducer.reducer,
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
