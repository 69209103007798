<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">
    <div class="modal-dialog custom-alert modal-dialog-centered" role="document">

        <div class="modal-content">

            <a *ngIf="message?.type == 'alert'" class="mr-2" (click)="message.yesFn()">
                <button type="button" class="btn-close btn"><i class="far fa-times-circle"></i></button>
            </a>

            <div *ngIf="message?.type == 'alert'" class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p class="text-center confirm-message">{{message.text}}</p>
                    </div>
                </div>
            </div>

            <div *ngIf="message?.type == 'confirm'" class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p class="text-center confirm-message">{{message.text}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="confirm-button">
                            <a class="mr-2" (click)="message.yesFn()">
                                <button class="btn btn-yes">Yes</button>
                            </a>
                            <a (click)="message.noFn()">
                                <button class="btn btn-no">No</button>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>