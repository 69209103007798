import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export class AppRouterLoader implements PreloadingStrategy {
    preload(route: Route, load: Function): Observable<any> {
        const loadRoute = (delay: any) => delay ? timer(15000).pipe(mergeMap(_ => load())) : load();
        return route.data && route.data.preload ? loadRoute(route.data.delay) : of(null);
    }
}
