import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import * as AuthAction from '../action/auth.actions';
import { GlobalService } from 'src/app/services';
import { ConfirmDialogService } from 'src/app/modules';


@Injectable()
export class AuthEffects {

	constructor(
		private actions$: Actions,
		private authService: AuthService,
		private router: Router,
		private gs: GlobalService,
		private confirmDialog: ConfirmDialogService,
	) { }

	login$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_LOGIN),
			mergeMap((params: AuthAction.login) =>
				this.authService.login(params.payload).pipe(
					map((response: any) => {
						if (response.code) {
							localStorage.setItem('token', JSON.stringify(response.token));
							localStorage.setItem('user', JSON.stringify(response.data));
							this.gs.alert('Login Success.', 'success');
							//console.log(response.data);
							//debugger;
							if(response.data.role === 'Coach'){
								/*if(response.data.userProfile.is_verified != 6){
									window.location.href = '/coach/survey';
								}else{
									window.location.href = '/coach/dashboard';
								}*/
								window.location.href = '/coach/dashboard';
							}else{
								window.location.href = '/user/dashboard';
							}
							return new AuthAction.loginSuccess(response.data);
						} else {
							this.gs.alert('Your login fail.', 'danger');
							return new AuthAction.loginFailure(response.message);
						}
					}),
					catchError(error => {
						this.gs.handleErrors(error);
						return [new AuthAction.loginFailure(error)]
					})
				)
			)
		)
	);

	loginByAuth$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_BYAUTH),
			mergeMap((params: AuthAction.loginByAuth) =>
				this.authService.oauth(params.params, params.params2).pipe(
					map((response: any) => {
						console.log('response', response);
						if (response.code) {
							localStorage.setItem('token', JSON.stringify(response.token));
							localStorage.setItem('user', JSON.stringify(response.data));
							this.gs.alert('Login Success.', 'success');
							//window.location.href = '/';
							//console.log(response.data);
							//debugger;
							if(response.data.role === 'Coach'){
								if(response.data.userProfile.is_verified!=6){
									window.location.href = '/coach/survey';
								}else{
									window.location.href = '/coach/dashboard';
								}
							}else{
								window.location.href = '/user/dashboard';
							}	
							return new AuthAction.loginSuccess(response.data);
						} else {
							this.gs.alert('Your login fail.', 'danger');
							return new AuthAction.loginFailure(response.message);
						}
					}),
					catchError(error => {
						this.gs.handleErrors(error);
						return [new AuthAction.loginFailure(error)]
					})
				)
			)
		)
	);

	register$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_REGISTER),
			mergeMap((params: AuthAction.register) => this.authService.register(params.payload).pipe(
				map((response: any) => {
					const is_guest_checkout = params.payload.is_guest_checkout;
					const order_id = params.payload.order_id;
					if(is_guest_checkout === 1) {
						localStorage.removeItem('meeting_order_id');
						localStorage.setItem('token', JSON.stringify(response.token));
						localStorage.setItem('user', JSON.stringify(response.data));
						window.location.href = `/user/booking/${order_id}`;
						return new AuthAction.loginSuccess(response.data);
					}

					this.confirmDialog.confirmAlert("Registered Successfully Please Check the email for Complete the Sign Up Process", () => {
						console.log('No clicked');
					}, () => {
						console.log('No clicked');
					});



					return new AuthAction.loginMessage({ msg: '', url: (is_guest_checkout === 1) ? `/user/booking/${order_id}` : '/login' });
				}),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new AuthAction.loginFailure(error)]
				})
			))
		)
	);

	

	forgotPassword$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_FORGOTPASSWORD),
			mergeMap((action: AuthAction.forgotPassword) => this.authService.forgotPassword(action.payload).pipe(
				map((user: any) => {
					return new AuthAction.loginMessage({ msg: 'Check your email for further instructions.', url: '/login' });
				}),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new AuthAction.loginFailure(error)]
				})
			))
		)
	);

	emailVerification$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_EMAIL_VERIFICATION),
			mergeMap((action: AuthAction.emailVerification) => this.authService.emailVerification(null, { token: action.payload }).pipe(
				map((user: any) => new AuthAction.loginMessage({ msg: user.message, url: '/login' })),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new AuthAction.loginFailure(error)]
				})
			))
		)
	);

	resetPassword$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_RESETPASSWORD),
			mergeMap((action: AuthAction.resetPassword) => this.authService.resetPassword(action.payload, { token: action.token }).pipe(
				map((user: any) => new AuthAction.loginMessage({ msg: 'You have successfully updated your password.', url: '/login' })),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new AuthAction.loginFailure(error)]
				})
			))
		)
	);

	/*logout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_LOGOUT),
			mergeMap((action: AuthAction.loginLogout) => this.authService.logout().pipe(
				map((user: any) => {
					return new AuthAction.loginMessage({ msg: 'Logout Successful.', url: '/' })
				}),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new AuthAction.loginFailure(error)]
				})
			))
		)
	);*/

	logout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_LOGOUT),
			switchMap((payload: any) => this.authService.logout()),
			switchMap((res) => {
				console.log('resresresres', res);
				return [
					//new AuthAction.loginLogout(),
					//new authActions.AuthLoginSuccess(res)
				]
			}), catchError((err: any) => {
				return [new AuthAction.loginFailure({ error: err })]
			})
		)
	);

	handleError$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_MESSAGE),
			map((action: AuthAction.loginMessage) => {
				const response = action.payload;
				this.gs.alert(response.msg, 'success');
				this.router.navigate([response.url]);
			}),
			tap((data) => {
				this.router.navigate([`/`])
			})
		), { dispatch: false }
	);

	localStorage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_SUCCESS),
			map((action: AuthAction.loginSuccess) => {
				const response = action.user;
				localStorage.setItem('user', JSON.stringify(response));
			})
		), { dispatch: false }
	);

}
