import { Component, Input, OnInit } from '@angular/core';
import { Role, Status } from 'src/app/enums';
import { Order } from 'src/app/models';
import { GlobalService, ItemService } from 'src/app/services';
import * as moment from 'moment-timezone';
import { ConfirmDialogService } from 'src/app/modules';
import { OrdersAction } from 'src/app/store/action';

@Component({
	selector: 'app-booking-box',
	templateUrl: './booking-box.component.html',
	styleUrls: ['./booking-box.component.scss'],
})
export class BookingBoxComponent implements OnInit {
	@Input() item: Order | null = null;
	moment = moment;
	role = Role;
	status = Status;
	constructor(
		public gs: GlobalService,
		private itemService: ItemService,
		public confirmDialog: ConfirmDialogService,
	) {
		
	}

	ngOnInit(): void {
		
	}

	get user() {
		let user = (this.gs.identity.role === this.role.Coach) ? this.item?.user : this.item?.userCatalogOrdersItems[0]?.catalog?.user;
		return user;
	}

	get order() {
		let order = this.item?.userCatalogOrdersItems[0];
		return order;
	}

	remove() {
		this.confirmDialog.confirmThis("Are you sure, you want to delete this booking?", () => {
			this.itemService.orders("DELETE", null, { order_id: this.item?.id }).subscribe(response => {
				let data = this.gs.apiResponce(response);
				this.gs.alert('You have successfully deleted your booking.');
				this.gs.store.dispatch(new OrdersAction.Delete(this.item));
			}, (error: Response) => {
				this.gs.handleErrors(error);
			})
		}, () => {
			console.log('No clicked');
		});
	}

}
