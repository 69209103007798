<!-- <iframe src="https://counts.live/embeds/youtube-subscriber-count/UCq-Fj5jknLsUf-MWSy4_brA/small" frameborder="0" style="height:80px;width:300px;border:none;"></iframe> -->

<!-- <iframe #instagaram id="instagaram" src="https://counts.live/embeds/instagram-follower-count/fspcoaching/small" frameborder="0" style="height:80px;width:300px;border:none;" ></iframe> -->

<div class="header">
    <div class="container">
        <div class="row">
            <div class="col-sm-2">
                <div class="logo"><a routerLink="/"><img src="/assets/images/Logo.svg" alt="" /></a></div>
            </div>
            <div class="col-sm-10">
                <div class="header-top">
                        <a href="https://www.facebook.com/Reakt3-101051585503474/" class="fb" target="_blank">
                            <i class="fab fa-facebook-f"></i> Like 
                        </a>

                    <div class="sociel-media">
                        <!-- <a href="https://www.facebook.com/iamtente/" target="_blank">
                           
                            <fb-like href="https://www.facebook.com/iamtente" width="50" layout="button_count" action="like" size="small" [share]="false"></fb-like>
                        </a> -->
                        <a href="https://www.instagram.com/fspcoaching/" target="_blank" class="instagram-count">
                            <i class="fab fa-instagram"></i>
                            <span>
                                7k
                            </span> followers
                        </a>
                        <a href="https://www.youtube.com/channel/UCuYUc-iSUMPMZlfKqMS2hZA" target="_blank">
                            <!-- <i class="fab fa-youtube"></i><span>137K+</span> Subscribers -->
                            <iframe src="https://www.youtube.com/subscribe_embed?channelid=UCuYUc-iSUMPMZlfKqMS2hZA" frameborder="0" style="height:25px;width:105px;border:none; pointer-events: none; margin: 3px 0 0 0;"></iframe>
                        </a>
                    </div>
                    <!-- <div class="phone">
                        <a href="to:+1-(327)-66676-123"><img src="/assets/images/svg/phone-call.svg" alt="" />+1 (327) 66676 123</a>
                    </div> -->
                    <div class="layout-false">
                        <div class="fb-like" data-href="https://www.facebook.com/iamtente" data-width="50" data-layout="button_count" data-action="like" data-size="small" data-share="false"></div>
                    </div>
                    
                </div>
                <div class="header-bottom"> 
                    <div class="mainmenu">
                        <nav class="navbar navbar-expand-lg navbar-light">
                            <div class="container">
                                <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
                                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                        <!-- <ng-container *ngIf="(((user | async)?.role) != role.Coach)">
                                            <li class="nav-item">
                                                <a class="nav-link" [routerLinkActive]="['active']" routerLink="/coach/search">COACHES</a> |
                                            </li>
                                        </ng-container> -->
                                        <!-- <ng-container *ngIf="!(isLoggedIn | async)">
                                            <li class="nav-item">
                                                <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" routerLink="/login">COACHES</a> |
                                            </li>
                                        </ng-container> -->
                                        <!-- <ng-container *ngIf="(isLoggedIn | async)"> -->
                                        <ng-container>
                                            <!-- <li class="nav-item" *ngIf="(((user | async)?.role) === role.User)"> -->
                                            <li class="nav-item">
                                                <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }"  [routerLinkActive]="['active']" routerLink="/coach/search">COACHES</a> |
                                            </li>
                                            <!-- </li> -->
                                        </ng-container>
                                        <!-- <ng-container *ngIf="!(isLoggedIn | async)"> -->
                                            <li class="nav-item">
                                                <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" routerLink="/" (click)="alert()">
                                                    CLASSES
                                                </a> |
                                            </li>
                                        <!-- </ng-container> -->
                                        <!-- <ng-container *ngIf="(isLoggedIn | async)">
                                            
                                                <li class="nav-item" *ngIf="(((user | async)?.role) === role.Coach)">
                                                    <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" routerLink="/coach/classes">CLASSES</a> |
                                                </li>
                                           
                                        </ng-container> -->
                                        <!-- <li class="nav-item">
                                            <a class="nav-link" [routerLinkActive]="['active']" routerLink="/classes">Classes</a> |
                                        </li> -->
                                        <li class="nav-item">
                                            <a class="nav-link" [routerLinkActive]="['active']" routerLink="/about">ABOUT</a> |
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" [routerLinkActive]="['active']" routerLink="/">BLOG</a> |
                                        </li>
                                        
                                        <li class="nav-item">
                                            <a class="nav-link" [routerLinkActive]="['active']" routerLink="/contact">CONTACT</a> 
                                        </li>
                                        <!-- <ng-container *ngIf="(((user | async)?.role) == role.Coach)">
                                            <li class="nav-item">
                                                <a class="nav-link" [routerLinkActive]="['active']" routerLink="/coach/view/{{(user | async)?.id}}">PREVIEW </a>
                                            </li>
                                        </ng-container>  -->
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div class="header-right">
                        <div class="login-menu" *ngIf="!(isLoggedIn | async)">
                            <a routerLink="/login">Login</a> |
                            <a routerLink="/register">Register</a>
                        </div>

                        
                        <ng-container *ngIf="(((user | async)?.role) === role.Coach)">
                            <div class="logged-menu" *ngIf="(isLoggedIn | async)">
                                <ul class="navbar-nav" >
                                    <li class="nav-item" ngbDropdown>
                                        <a class="nav-link" ngbDropdownToggle>
                                            {{(user | async)?.name}}
                                        </a>
                                        <ul ngbDropdownMenu>                      
                                            <li><a ngbDropdownItem routerLink="/coach/dashboard">Dashboard</a></li>
                                            <li><a ngbDropdownItem routerLink="/coach/profile">Profile</a></li>
                                            <li><a ngbDropdownItem routerLink="/coach/change-password">Change Password</a></li>
                                            <li><a ngbDropdownItem routerLink="/coach/view/{{(user | async)?.id}}">Preview</a></li>
                                            <li *ngIf="(isLoggedIn | async)">
                                                <hr class="dropdown-divider">
                                            </li>
                                            <li *ngIf="(isLoggedIn | async)"><a ngbDropdownItem routerLink="/" (click)="logout()">Logout <small>({{(user | async)?.name}})</small></a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="(((user | async)?.role) === role.User)">
                            <div class="logged-menu" *ngIf="(isLoggedIn | async)">
                                <ul class="navbar-nav" >
                                    <li class="nav-item" ngbDropdown>
                                        <a class="nav-link" ngbDropdownToggle>
                                            {{(user | async)?.name}}
                                        </a>
                                        <ul ngbDropdownMenu >
                                            <li><a ngbDropdownItem routerLink="/user/dashboard">Dashboard</a></li>
                                                <li><a ngbDropdownItem routerLink="/user/profile">Profile</a></li>
                                                <li><a ngbDropdownItem routerLink="/user/change-password">Change Password</a></li>
                                            
                                            <li *ngIf="(isLoggedIn | async)">
                                                <hr class="dropdown-divider">
                                            </li>
                                            <li *ngIf="(isLoggedIn | async)"><a ngbDropdownItem routerLink="/" (click)="logout()">Logout <small>({{(user | async)?.name}})</small></a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="(((user | async)?.role) === role.User)">
                            <div class="headersearch">
                                <a routerLink="/coach/search" class="btn-search"><img src="/assets/images/svg/search.svg" alt="" /></a>
                            </div>
                        </ng-container>
                        <div class="menulink" *ngIf="!(isLoggedIn | async)"><a routerLink="/coach/register">BECOME A COACH</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>