import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as locationReducer from '../reducer/locations.reducer';

// Lookup the 'Joke' feature state managed by NgRx
const getState = createFeatureSelector<locationReducer.State>(
    locationReducer.featureKey
);

export const locations = createSelector(
    getState,
    state => state.locations
);

export const view = createSelector(
    getState,
    state => state.view
);

export const error = createSelector(
    getState,
    state => state.error
);

export const loading = createSelector(
    getState,
    state => state.loading
);