import {Action} from '@ngrx/store';
import {Product} from 'src/app/models';
import {Wishlist} from "../../models/Wishlist";

export enum ProductsActionTypes {
  PRODUCTS_ATTRIBUTE_PARAMS = '[PRODUCTS_ATTRIBUTE_PARAMS] Product Attribute Params',
  PRODUCTS_ATTRIBUTES = '[PRODUCTS_ATTRIBUTES] Product Attribute',
  PRODUCTS_FIELDS_PARAMS = '[PRODUCTS_FIELDS_PARAMS] Product Fields Params',
  PRODUCTS_FIELDS = '[PRODUCTS_ATTRIBUTES] Product Fields',
  PRODUCTS_OPTIONS_PARAMS = '[PRODUCTS_OPTIONS_PARAMS] Product Options Params',
  PRODUCTS_OPTIONS = '[PRODUCTS_ATTRIBUTES] Product Options',
  PRODUCTS_SEARCH_PARAMS = '[PRODUCTS_SEARCH_PARAMS] Product Search Params',
  PRODUCTS_SEARCH = '[PRODUCTS_SEARCH] Product Search',

  PRODUCTS_WISHLIST_LIST_PARAMS = '[PRODUCTS_WISHLIST_LIST_PARAMS] Product Wishlist List Params',
  PRODUCTS_WISHLIST_LIST = '[PRODUCTS_WISHLIST_LIST] Product Wishlist List',
  PRODUCTS_ADD_WISHLIST_LIST = '[PRODUCTS_ADD_WISHLIST_LIST] Product Add Wishlist List Params',
  PRODUCTS_REMOVE_WISHLIST_LIST = '[PRODUCTS_REMOVE_WISHLIST_LIST] Product Remove Wishlist List Params',
  PRODUCTS_FEATURED_LIST_PARAMS = '[PRODUCTS_FEATURED_LIST_PARAMS] Product Wishlist List Params',
  PRODUCTS_FEATURED_LIST = '[PRODUCTS_FEATURED_LIST] Product Wishlist List Params',

  PRODUCTS_MY_LIST_PARAMS = '[PRODUCTS_MY_LIST_PARAMS] My Product List Params',
  PRODUCTS_VIEW = '[PRODUCTS_VIEW] View Product',
  PRODUCTS_ADD = '[PRODUCTS_ADD] Add Product',
  PRODUCTS_UPDATE = '[PRODUCTS_UPDATE] Update Product',
  PRODUCTS_DELETE = '[PRODUCTS_DELETE] Delete Product',
  PRODUCTS_MY_PRODUCTS = '[PRODUCTS_MY_PRODUCTS] My Product List',
  PRODUCTS_MY_PRODUCTS_MORE = '[PRODUCTS_MY_PRODUCTS_MORE] My Product More',
  PRODUCTS_ACTIVE_LIST = '[PRODUCTS_ACTIVE_LIST] My Product Active List',
  PRODUCTS_ACTIVE_LIST_MORE = '[PRODUCTS_ACTIVE_LIST_MORE] My Product Active More',
  PRODUCTS_INACTIVE_LIST = '[PRODUCTS_INACTIVE_LIST] My Product Inactive List',
  PRODUCTS_INACTIVE_LIST_MORE = '[PRODUCTS_INACTIVE_LIST_MORE] My Product Inactive More',
  PRODUCTS_CURRENT_AUCTIONS_LIST = '[PRODUCTS_CURRENT_AUCTIONS_LIST] My Product Current Auctions List',
  PRODUCTS_CURRENT_AUCTIONS_LIST_MORE = '[PRODUCTS_CURRENT_AUCTIONS_LIST_MORE] My Product Current Auctions More',
  PRODUCTS_PAST_AUCTIONS_LIST = '[PRODUCTS_PAST_AUCTIONS_LIST] My Product Past Auctions List',
  PRODUCTS_PAST_AUCTIONS_LIST_MORE = '[PRODUCTS_PAST_AUCTIONS_LIST_MORE] My Product Past Auctions More',
  PRODUCTS_FAILURE = '[PRODUCTS_FAILURE] failed',
}


export class AttributeParams implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_ATTRIBUTE_PARAMS;

  constructor(public options: { params?: any, params2?: any }) {
  }
}

export class Attributes implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_ATTRIBUTES;

  constructor(public attributes: any) {
  }
}

export class FieldsParams implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_FIELDS_PARAMS;

  constructor(public options: { params?: any, params2?: any }) {
  }
}

export class Fields implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_FIELDS;

  constructor(public fields: any) {
  }
}

export class OptionsParams implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_OPTIONS_PARAMS;

  constructor(public options: { params?: any, params2?: any }) {
  }
}

export class Options implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_OPTIONS;

  constructor(public options: any) {
  }
}

export class FeaturedProductsParams implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_FEATURED_LIST_PARAMS;
  constructor(public params?: any, public key?: string) {
  }
}

export class FeaturedProducts implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_FEATURED_LIST;
  constructor(public product?: any, public pagination?: any) {
  }
}

export class ProductSearchParams implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_SEARCH_PARAMS;
  constructor(public params?: any, public key?: string) {
  }
}

export class ProductSearch implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_SEARCH;
  constructor(public product?: any, public pagination?: any) {
  }
}

export class MyProductParams implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_MY_LIST_PARAMS;
  constructor(public method: string, public params?: any, public params2?: any, public key?: string) {
  }
}

export class Add implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_ADD;
  constructor(public item: any, public key?: string) {
  }
}

export class Update implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_UPDATE;
  constructor(public update: any, public key?: string) {
  }
}

export class Delete implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_DELETE;
  constructor(public item: any, public key?: string) {
  }
}

export class View implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_VIEW;

  constructor(public view: any) {
  }
}

export class MyProducts implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_MY_PRODUCTS;
  constructor(public products: any) {
  }
}

export class MyProductsMore implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_MY_PRODUCTS_MORE;
  constructor(public more: Product[] | null) {
  }
}

export class ActiveList implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_ACTIVE_LIST;

  constructor(public active: any) {
    //console.log('ActiveListActiveList', this.active);
  }
}

export class ActiveListMore implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_ACTIVE_LIST_MORE;

  constructor(public more: Product[]) {
  }
}

export class InactiveList implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_INACTIVE_LIST;

  constructor(public inactive: any) {
  }
}

export class InactiveListMore implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_INACTIVE_LIST_MORE;

  constructor(public more: Product[]) {
  }
}

export class CurrentAuctions implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_CURRENT_AUCTIONS_LIST;

  constructor(public current: any) {
  }
}

export class CurrentAuctionsMore implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_CURRENT_AUCTIONS_LIST_MORE;

  constructor(public more: Product[]) {
  }
}

export class PastAuctions implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_PAST_AUCTIONS_LIST;

  constructor(public past: any) {
  }
}

export class PastAuctionsMore implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_PAST_AUCTIONS_LIST_MORE;

  constructor(public more: Product[]) {
  }
}

export class failure implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_FAILURE;

  constructor(public error: any) {
  }
}

export class wishlistParams implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_WISHLIST_LIST_PARAMS;
  constructor(public method: any, public param?: any, public param2?: any, public key?: string, public item?: any) {
  }
}

export class wishlist implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_WISHLIST_LIST;
  constructor(public products?: Wishlist[], public pagination?: any) {
  }

}

export class addToWishlist implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_ADD_WISHLIST_LIST;
  constructor(public item?: any, public key?: string) {
  }
}

export class removeFromWishlist implements Action {
  readonly type = ProductsActionTypes.PRODUCTS_REMOVE_WISHLIST_LIST;
  constructor(public item?: any, public key?: string) {
  }
}


export type ProductsAction =
  AttributeParams
  | Attributes
  | FieldsParams
  | Fields
  | OptionsParams
  | Options
  | ProductSearchParams
  | ProductSearch
  | MyProductParams
  | View
  | Add
  | Update
  | Delete
  | MyProducts
  | wishlistParams
  | wishlist
  | addToWishlist
  | removeFromWishlist
  | MyProductsMore
  | ActiveList
  | ActiveListMore
  | InactiveList
  | InactiveListMore
  | CurrentAuctions
  | CurrentAuctionsMore
  | PastAuctions
  | PastAuctionsMore
  | failure
  | FeaturedProductsParams
  | FeaturedProducts;
