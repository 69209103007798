import { Pagination } from 'src/app/models';
import { CertificationAction, CertificationActionTypes } from '../action/certification.actions';

export const featureKey = 'certification';

export interface State {
	certification: any,
	pagination: Pagination | null,
	loading: boolean,
	error: any
}

export const initialState: State = {
	certification: null,
	pagination: null,
	loading: false,
	error: ''
};


export function reducer(state = initialState, action: CertificationAction | any): State {
	switch (action.type) {
		case CertificationActionTypes.PARAMS:
			return {
				...state,
				loading: true,
				error: '',
			}
		case CertificationActionTypes.LIST:
			return {
				...state,
				loading: false,
				certification: action.list ? action.list.items : [],
				pagination: action.list ? action.list.pagination : null
			}
		case CertificationActionTypes.ADD:
			return {
				...state,
				certification: [action.item, ...state.certification],
				loading: false
			}
		case CertificationActionTypes.UPDATE:
			const index = state.certification.findIndex((f: any) => f.id === action.item.id);
			return {
				...state,
				certification: [...state.certification.slice(0, index),
				action.item,
				...state.certification.slice(index + 1)
				],
				loading: false
			}
		case CertificationActionTypes.DELETE:
			return {
				certification: [...state.certification.filter( (val: any) => val.id !== action.item.id )],
				pagination: state.pagination,
				loading: false,
				error: '',
			}
		case CertificationActionTypes.FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			}
		default:
			return state;
	}
}