
<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="image">
                    <a routerLink="/"><img src="/assets/images/Logo.svg" alt="" /></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="footerinfo">
                    <div class="content">
                        Reakt3 is a Digital Platform which connect professional coaches in various disciplines such as nutrition, fitness and mental health, and people that want to reach their full potential in life. By rethinking the way you train, our main purpose is to create a new approach so you can conquer those goals anytime anywhere. Are you ready for a life changing experience?
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-4">
                <div class="footer-address">
                    <ul>
                        <li><img src="/assets/images/svg/map-pin.svg" alt="" />Rue de Gravelines 71, 1000 Bruxelles.</li>
                        <li><img src="/assets/images/svg/email.svg" alt="" /><a href="mailto:contact@reakt3.com">contact@reakt3.com</a></li>
                        <li><img src="/assets/images/svg/phone-call-footer.svg" alt="" />(+32)470 051 554</li>
                    </ul>
                </div>
            </div> -->
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="sociel-media">
                    <a href="https://www.facebook.com/Reakt3-101051585503474/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                    <a href="https://www.instagram.com/reaktplatform/" target="_blank"><i class="fab fa-instagram"></i></a>
                    <a href="https://www.youtube.com/channel/UCuYUc-iSUMPMZlfKqMS2hZA" target="_blank"><i class="fab fa-youtube"></i></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="copymenu">
                    <a routerLink="/">Home</a> |
                    <!-- <ng-container *ngIf="!(isLoggedIn | async)">
                        <a routerLink="/login">Coaches</a> |
                    </ng-container> -->
                    <ng-container>
                    <!-- <ng-container *ngIf="(((user | async)?.role) === role.User)"> -->
                        <!-- <span *ngIf="(((user | async)?.role) === role.User)"> -->
                            <a routerLink="/coach/search">Coaches</a> |
                        <!-- </span>     -->
                    </ng-container>

                    <!-- <ng-container *ngIf="!(isLoggedIn | async)">  -->
                        <a routerLink="/" (click)="alert()">Classes</a> |
                    <!-- </ng-container> -->
                    <!-- <ng-container *ngIf="(isLoggedIn | async)">
                        <span *ngIf="(((user | async)?.role) === role.Coach)">
                            <a routerLink="/coach/classes">Classes</a> |
                        </span>
                    </ng-container> -->

                    <a routerLink="/about">About</a> |
                    <a routerLink="/contact">Contact</a> |
                    <ng-container *ngIf="!(isLoggedIn | async)">
                        <a routerLink="/login">Login</a> |
                        <a routerLink="/register">Register</a> |
                    </ng-container>
                    <a routerLink="/pages/privacy-policy">Privacy Policy</a> |
                    <a routerLink="/pages/terms-condition">Terms of Service</a> |
                    <a routerLink="/faqs" [queryParams]="{ category_id: 15}" [queryParamsHandling]="'merge'">FAQ</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="copyright">Reakt3. © {{currentYearLong()}} All Rights Reserved.</div>
            </div>
        </div>
    </div>
</div>
