import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-base',
  template: ``,
})
export class BaseComponent implements OnInit {

  form!: FormGroup; 
  constructor() { } 
  
  ngOnInit(): void {

  }

  get f() { return this.form.controls }; 
}
