import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as itemReducer from '../reducer/item.reducer';

// Lookup the 'Joke' feature state managed by NgRx
const getState = createFeatureSelector<itemReducer.State>(
    itemReducer.featureKey
);

export const staffs = createSelector(
    getState,
    state => state.staffs
);

export const courts = createSelector(
    getState,
    state => state.courts
);

export const error = createSelector(
    getState,
    state => state.error
);

export const loading = createSelector(
    getState,
    state => state.loading
);