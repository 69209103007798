import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/User';
import { DefaultService } from 'src/app/services/default.service';
import { GlobalService } from 'src/app/services/global.service';
import { UserAction } from 'src/app/store/action';
import { AuthSelector } from 'src/app/store/selector';

@Component({
	selector: 'app-profile-avatar',
	templateUrl: './profile-avatar.component.html',
	styleUrls: ['./profile-avatar.component.scss']
})
export class ProfileAvatarComponent implements OnInit {
	loading: boolean = false;
	profile: Observable<User | null> = this.gs.store.select(AuthSelector.getUser);
	constructor(
		private gs: GlobalService,
		private defaultService: DefaultService,
	) { }

	ngOnInit(): void {
	}

	upload(event: any) {
		debugger
		let files: FileList = event.target.files;
		if (files.length > 0) {
			this.loading = true;
			this.defaultService.basicUpload(files).subscribe(data => {
				let fileId = data.files[0].id;
				//this.gs.store.dispatch(new UserAction.Profile({ method: 'POST', params: { UserProfile: { avatar_id: fileId }} }));
				this.gs.store.dispatch(new UserAction.Profile("POST", { UserProfile: { avatar_id: fileId }}, 'avatar'));
				this.loading = false;
			}, (error: Response) => {
				this.loading = false;
				this.gs.handleErrors(error);
			})
		}
	}

}
