import { Action } from '@ngrx/store';
import { Countries, States } from 'src/app/models';

export enum DefaultActionTypes {
    DEFAULT_COUNTRIES_PARAMS = '[DEFAULT_COUNTRIES_PARAMS] Default Countries',
    DEFAULT_STATES_PARAMS = '[DEFAULT_STATES_PARAMS] Default Countries',
    DEFAULT_CATEGORIES_PARAMS = '[DEFAULT_CATEGORIES_PARAMS] Default Categories',
    DEFAULT_COUNTRIES = '[DEFAULT_COUNTRIES] Countries',
    DEFAULT_STATES = '[DEFAULT_STATES] Countries',
    DEFAULT_CATEGORIES = '[DEFAULT_CATEGORIES] Categories',
    DEFAULT_CONFIG = '[DEFAULT_CONFIG] Configs',
    DEFAULT_PLATFORMS = '[DEFAULT_PLATFORMS] Platforms',
    DEFAULT_FAILURE = '[DEFAULT_FAILURE] failed',
}

export class countriesParams implements Action {
    readonly type = DefaultActionTypes.DEFAULT_COUNTRIES_PARAMS;  
} 

export class statesParams implements Action {
    readonly type = DefaultActionTypes.DEFAULT_STATES_PARAMS;
    constructor(public params: any) { }
}

export class CategoriesParams implements Action {
    readonly type = DefaultActionTypes.DEFAULT_CATEGORIES_PARAMS;
    constructor(public params: any = null) { }
}

export class countries implements Action {
    readonly type = DefaultActionTypes.DEFAULT_COUNTRIES;
    constructor(public countries: Countries[]) { }
}

export class states implements Action {
    readonly type = DefaultActionTypes.DEFAULT_STATES;
    constructor(public states: States[]) { }
}

export class Categories implements Action {
    readonly type = DefaultActionTypes.DEFAULT_CATEGORIES;
    constructor(public categories: any) { }
}

export class config implements Action {
    readonly type = DefaultActionTypes.DEFAULT_CONFIG;
    constructor(public config: any) { }
}

export class platforms implements Action {
    readonly type = DefaultActionTypes.DEFAULT_PLATFORMS;
    constructor(public platforms: any) { }
}

export class failure implements Action {
    readonly type = DefaultActionTypes.DEFAULT_FAILURE;
    constructor(public error: any) { }
}

export type DefaultAction = countriesParams | statesParams | countries | states | config | platforms | CategoriesParams | Categories | failure;