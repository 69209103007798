import { Pagination } from 'src/app/models';
import { ExpertiseAction, ExpertiseActionTypes } from '../action/expertise.actions';

export const featureKey = 'expertise';

export interface State {
	expertise: any,
	pagination: Pagination | null,
	loading: boolean,
	error: any
}

export const initialState: State = {
	expertise: null,
	pagination: null,
	loading: false,
	error: ''
};


export function reducer(state = initialState, action: ExpertiseAction | any): State {
	switch (action.type) {
		case ExpertiseActionTypes.PARAMS:
			return {
				...state,
				loading: true,
				error: '',
			}
		case ExpertiseActionTypes.LIST:
			return {
				...state,
				loading: false,
				expertise: action.list ? action.list.items : [],
				pagination: action.list ? action.list.pagination : null
			}
		case ExpertiseActionTypes.ADD:
			return {
				...state,
				expertise: [action.item, ...state.expertise],
				loading: false
			}
		case ExpertiseActionTypes.UPDATE:
			const index = state.expertise.findIndex((f: any) => f.id === action.item.id);
			return {
				...state,
				expertise: [...state.expertise.slice(0, index),
				action.item,
				...state.expertise.slice(index + 1)
				],
				loading: false
			}
		case ExpertiseActionTypes.DELETE:
			return {
				expertise: [...state.expertise.filter( (val: any) => val.id !== action.item.id )],
				pagination: state.pagination,
				loading: false,
				error: '',
			}
		case ExpertiseActionTypes.FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			}
		default:
			return state;
	}
}