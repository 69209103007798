import { Action } from '@ngrx/store';
import { Education } from 'src/app/models';

export enum CertificationActionTypes {
    PARAMS = '[CERTIFICATION_PARAMS] Certification List Params',
    LIST = '[CERTIFICATION_LIST] Certification List',
    ADD = '[CERTIFICATION_ADD] Certification Add',
    UPDATE = '[CERTIFICATION_UPDATE] Certification Update',
    DELETE = '[CERTIFICATION_DELETE] Certification Detele',
    FAILURE = '[CERTIFICATION_FAILURE] failed',
}

export class Params implements Action {
    readonly type = CertificationActionTypes.PARAMS;
    constructor(public method: string, public params?: any, public params2?: any, public key?: string) { }
}

export class List implements Action {
    readonly type = CertificationActionTypes.LIST;
    constructor(public list: any) { }
}

export class Add implements Action {
    readonly type = CertificationActionTypes.ADD;
    constructor(public item: Education) { }
}

export class Update implements Action {
    readonly type = CertificationActionTypes.UPDATE;
    constructor(public item: Education) { }
}

export class Delete implements Action {
    readonly type = CertificationActionTypes.DELETE;
    constructor(public item: any) { }
}

export class failure implements Action {
    readonly type = CertificationActionTypes.FAILURE;
    constructor(public error: any) { }
}


export type CertificationAction = Params | List | Add | Update | Delete | failure;