import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as EducationsReducer from '../reducer/educations.reducer';

const getState = createFeatureSelector<EducationsReducer.State>(
    EducationsReducer.featureKey
);

export const educations = createSelector(
    getState,
    state => state.educations
);

export const loading = createSelector(
    getState,
    state => state.loading
);