import { Photo } from './Photo';

export class Consultation {
    id!: number;
    staff_id!: number;
    user_id!: number;
    method!: string;
    first_name!: string;
    last_name!: string;
    email!: string;
    telephone!: number;
    date_time!: number;
    subject!: string;
    message!: string;
    created_at!: number;
    updated_at!: number;
    media!: Media
}

export class Media {
    image!: Photo[];
    docs!: Photo[]
}