export class Photo {
    id!: number;
    guid!: string;
    filename!: string;
    filesize!: string;
    path!: string;
    mimetype!: string;
    type!: string;
    thumb!: string;
    banner!: string;
    videoUrl!: string;
    status!: string;
    created_at!: string;
    updated_at!: string;
}