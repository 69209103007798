import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as AvailabilityReducer from '../reducer/availability.reducer';

const getState = createFeatureSelector<AvailabilityReducer.State>(
    AvailabilityReducer.featureKey
);

export const availability = createSelector(
    getState,
    state => state.availability
);

export const loading = createSelector(
    getState,
    state => state.loading
);