import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ItemService, GlobalService } from 'src/app/services';
import { AuthActions, AvailabilityAction, CertificationAction, EducationsAction, ExperienceAction, ExpertiseAction, LocationAction, UserAction } from '../action';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private itemService: ItemService,
        private gs: GlobalService
    ) { }

    profile$ = createEffect(() => this.actions$.pipe(
        ofType(UserAction.UserActionTypes.USER_PROFILE),
        mergeMap((options: UserAction.Profile) =>
            this.itemService.profile(options?.method, options?.params).pipe(
                map((response: any) => {
                    let key = options.key;
                    let message = null;
                    //let redirectTo = '';
                    if (response.code) {
                        switch (key) {
                            case 'profile':
                                message = 'You have updated your profile.';
                                //redirectTo = '/user/experience-and-education';
                                break;
                            case 'avatar':
                                message = 'You have uploaded your picture.';
                                break;
                            case 'banner':
                                message = 'You have uploaded your banner photo.';
                                break;
                            case 'address-update':
                                //redirectTo = 'address/create';
                                break;
                            default:
                        }
                        if (key !== 'load_profile') {
                            this.gs.alert(message, 'success');
                        }
                        //redirectTo && this.gs.router(redirectTo);
                        //this.gs.store.dispatch(new UserAction.ProfileSuccess(true));
                        return new AuthActions.loginSuccess(response.data)
                    } else {
                        this.gs.alert('Action not perform', 'danger');
                        return new UserAction.failure('Action not perform');
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new UserAction.failure(error)]
                })
            ))
    ));

    IsAvailable$ = createEffect(() => this.actions$.pipe(
        ofType(UserAction.UserActionTypes.USER_IS_AVAILABLE),
        mergeMap((options: UserAction.IsAvailable) =>
            this.itemService.is_available(options?.params).pipe(
                map((response: any) => {
                    response = this.gs.apiResponce(response);
                    if (response.code) {
                        this.gs.alert('You have successfully update your availability.');
                        this.gs.store.dispatch(new UserAction.Profile("GET", null, 'load_profile'));
                        return new UserAction.failure(response.data);
                    } else {
                        this.gs.alert('Action not perform', 'danger');
                        return new UserAction.failure('Action not perform');
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new UserAction.failure(error)]
                })
            ))
    ));

    experience$ = createEffect(() => this.actions$.pipe(
        ofType(ExperienceAction.ExperienceActionTypes.PARAMS),
        mergeMap((params: ExperienceAction.Params) =>
            this.itemService.experience(params.method, params.params, params.params2).pipe(
                map((item: any) => {
                    let key = params.key;
                    let msg = `You have successfully ${key} your experience.`;
                    switch (key) {
                        case 'add':
                            this.gs.alert(msg);
                            return new ExperienceAction.Add(item.data);
                            break;
                        case 'update':
                            this.gs.alert(msg);
                            return new ExperienceAction.Update(item.data);
                            break;
                        case 'delete':
                            this.gs.alert(msg);
                            return new ExperienceAction.Delete({id: params.params2.item_id});
                            break;
                        default:
                            return new ExperienceAction.List(item.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new ExperienceAction.failure(error)]
                })
            ))
    ));

    educatins$ = createEffect(() => this.actions$.pipe(
        ofType(EducationsAction.EducationsActionTypes.PARAMS),
        mergeMap((params: EducationsAction.Params) =>
            this.itemService.education(params.method, params.params, params.params2).pipe(
                map((item: any) => {
                    let key = params.key;
                    let msg = `You have successfully ${key} your education.`;
                    switch (key) {
                        case 'add':
                            this.gs.alert(msg);
                            return new EducationsAction.Add(item.data);
                            break;
                        case 'update':
                            this.gs.alert(msg);
                            return new EducationsAction.Update(item.data);
                            break;
                        case 'delete':
                            this.gs.alert(msg);
                            return new EducationsAction.Delete({id: params.params2.item_id});
                            break;
                        default:
                            return new EducationsAction.List(item.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new EducationsAction.failure(error)]
                })
            ))
    ));

    certification$ = createEffect(() => this.actions$.pipe(
        ofType(CertificationAction.CertificationActionTypes.PARAMS),
        mergeMap((params: CertificationAction.Params) =>
            this.itemService.award(params.method, params.params, params.params2).pipe(
                map((item: any) => {
                    let key = params.key;
                    let msg = `You have successfully ${key} your certification.`;
                    switch (key) {
                        case 'add':
                            this.gs.alert(`You have successfully added your certification.`);
                            return new CertificationAction.Add(item.data);
                            break;
                        case 'update':
                            this.gs.alert(`You have successfully updated your certification.`);
                            return new CertificationAction.Update(item.data);
                            break;
                        case 'delete':
                            this.gs.alert(`You have successfully deleted your certification.`);
                            return new CertificationAction.Delete({id: params.params2.item_id});
                            break;
                        default:
                            return new CertificationAction.List(item.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new CertificationAction.failure(error)]
                })
            ))
    ));


    expertise$ = createEffect(() => this.actions$.pipe(
        ofType(ExpertiseAction.ExpertiseActionTypes.PARAMS),
        mergeMap((params: ExpertiseAction.Params) =>
            this.itemService.expertise(params.method, params.params, params.params2).pipe(
                map((item: any) => {
                    let key = params.key;
                    let msg = `You have successfully ${key} your class.`;
                    switch (key) {
                        case 'add':
                            this.gs.alert('You have successfully added your class.');
                            return new ExpertiseAction.Add(item.data);
                            break;
                        case 'update':
                            this.gs.alert('You have successfully updated your class.');
                            return new ExpertiseAction.Update(item.data);
                            break;
                        case 'delete':
                            this.gs.alert('You have successfully deleted your class.');
                            return new ExpertiseAction.Delete({id: params.params2.item_id});
                            break;
                        default:
                            return new ExpertiseAction.List(item.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new ExpertiseAction.failure(error)]
                })
            ))
    ));

    availability$ = createEffect(() => this.actions$.pipe(
        ofType(AvailabilityAction.AvailabilityActionTypes.PARAMS),
        mergeMap((params: AvailabilityAction.Params) =>
            this.itemService.availability(params.method, params.params, params.params2).pipe(
                map((response: any) => {
                    let item: any = this.gs.apiResponce(response);
                    let key = params.key;
                    let msg = `You have successfully ${key} your availability.`;
                    
                    switch (key) {
                        case 'add':
                            if(response.code){
                                this.gs.alert('You have successfully added your availability.');
                            }else{
                                this.gs.alert('Invalid data.','danger');                                
                            }    
                            return new AvailabilityAction.Add(item.data);
                            break;
                        case 'update':
                            if(response.code){
                                this.gs.alert('You have successfully updated your availability.');
                            
                            }else{
                                this.gs.alert('Invalid data.','danger');                                
                            }   

                            return new AvailabilityAction.Update(item.data);
                            break;
                        case 'update_blank':
                                if(response.code){
                                    this.gs.alert('You have successfully added your availability.');
                                }else{
                                    this.gs.alert('Invalid data.','danger');                                
                                }    
                            
                            let blank = params.item;
                            let newItem = item.data;
                            newItem['blank_id'] = blank.blank_id;
                            return new AvailabilityAction.UpdateBlank(newItem);
                            break;
                        case 'delete':
                            this.gs.alert('You have successfully deleted your availability.');
                            return new AvailabilityAction.Delete({id: params.params2.item_id});
                            break;
                        default:
                            return new AvailabilityAction.List(item.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new AvailabilityAction.failure(error)]
                })
            ))
    ));

    locations$ = createEffect(() => this.actions$.pipe(
        ofType(LocationAction.LocationActionTypes.LOCATION_PARAMS),
        mergeMap((params: LocationAction.Params) =>
            this.itemService.location(params.method, params?.params, params.params2).pipe(
                map((response: any) => {
                    let data = response;
                    const key = params?.key;
                    const mg = `You have successfully ${key} address.`;
                    if(!data.code) {
                        this.gs.alert('Request not accept', 'danger');
                    }
                    switch(key) {
                        case 'create':
                            this.gs.alert('You have successfully created address.', 'success');
                            this.gs.router('/address/list')
                            return new LocationAction.Add(data.data);
                            break;
                        case 'update':
                            this.gs.router('/address/list')
                            this.gs.alert('You have successfully updated address.', 'success');
                            return new LocationAction.Update(data.data);
                            break;
                        case 'delete':
                            this.gs.alert('You have successfully deleted address.', 'danger');
                            return new LocationAction.Delete(params.params2.item_id);
                            break;
                        case 'more':
                            return new LocationAction.More(data.data ? data.data.items : []);
                            break;
                        case 'view':
                            return new LocationAction.View(data.data);
                            break;
                        default:
                            return new LocationAction.Locations(data.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new ExperienceAction.failure(error)]
                })
            ))
    ));


    transactions$ = createEffect(() => this.actions$.pipe(
        ofType(UserAction.UserActionTypes.TRANSACTIONS_PARAMS),
        mergeMap((params: UserAction.TransactionsParams) => 
            this.itemService.transactions(params.params).pipe(
                map((response: any) => {
                    return new UserAction.Transactions(response.data);
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new UserAction.failure(error)]
                })
            )
        )
    ))

}