export class Files {
    id!: number;
    base_url!: string;
    delete_url!: string;
    filename!: string;
    mimetype!: string;
    name!: string;
    path!: string;
    size!: string;
    type!: string;
    url!: string;
}