import { Files } from "./Files";

export class Award {
    id!: number;
    user_id!: number;
    institute!: string;
    title!: string;
    description!: string;
    file_id!: number;
    dated!: number;
    status!: any;
    created_at!: any;
    updated_at!: any;
    location!: any;
    file!: Files
}