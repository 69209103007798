import { Action } from '@ngrx/store';
import { Experience } from 'src/app/models';

export enum ExperienceActionTypes {
    PARAMS = '[EXPERIENCE_PARAMS] Experience List PARAMS',
    LIST = '[EXPERIENCE_LIST] Experience List',
    ADD = '[EXPERIENCE_ADD] Experience Add',
    UPDATE = '[EXPERIENCE_UPDATE] Experience Update',
    DELETE = '[EXPERIENCE_DELETE] Experience Detele',
    FAILURE = '[EXPERIENCE_FAILURE] Experience failed',
}

export class Params implements Action {
    readonly type = ExperienceActionTypes.PARAMS;
    constructor(public method: string, public params?: any, public params2?: any, public key?:string ) { }
}

export class List implements Action {
    readonly type = ExperienceActionTypes.LIST;
    constructor(public list: any) { }
}

export class Add implements Action {
    readonly type = ExperienceActionTypes.ADD;
    constructor(public item: Experience) { }
}

export class Update implements Action {
    readonly type = ExperienceActionTypes.UPDATE;
    constructor(public item: Experience) { }
}

export class Delete implements Action {
    readonly type = ExperienceActionTypes.DELETE;
    constructor(public item: any) { }
}

export class failure implements Action {
    readonly type = ExperienceActionTypes.FAILURE;
    constructor(public error: any) { }
}


export type ExperienceAction = Params | List | Add | Update | Delete | failure;