import { Photo } from './Photo';

export class Item {
    id!: number;
    category_id!: string;
    category_name!: string;
    restricted!: string;
    external_url!: string;
    status!: string;
    slug!: string;
    title!: string;
    content!: string;
    location!: string;
    image!: Photo;
    image_full!: string;
    media!: Photo[];
    view_count!: string;
    created_at!: number;
    updated_at!: number;
    cover_image!: Photo;
    product_price!: string;
    commentCount!: string;
    reactionCount!: string;
    likeCount!: string;
    reactions!: string;
    imageUrl!: Photo;
    linkPreview!: string;
    sector_icon!: Photo;
    sector_image!: Photo;
    blog_image!: Photo;
    articles_image!: Photo;
    news_image!: Photo;
    events_image!: Photo;
    leadership_image!: Photo;
    testimonials_image!: Photo;
    educational_image!: Photo[];
    research_image!: Photo[];
}