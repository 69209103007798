export class Categories {
    id!: number;
    banner!: string;
    thumb!: string;
    parent_id!: null;
    parent!: string;
    name!: string;
    media_id!: number;
    slug!: string;
    description!: string;
    sort_order!: number;
    created_at!: number;
    updated_at!: number;
    short_description!: string;
    options: any;
    prev!: boolean;
    next!: boolean;
    children!: Categories[];
}