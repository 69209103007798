import { Pagination } from 'src/app/models';
import { AvailabilityAction, AvailabilityActionTypes } from '../action/availability.actions';

export const featureKey = 'availability';

export interface State {
	availability: any,
	pagination: Pagination | null,
	loading: boolean,
	error: any
}

export const initialState: State = {
	availability: null,
	pagination: null,
	loading: false,
	error: ''
};


export function reducer(state = initialState, action: AvailabilityAction | any): State {
	switch (action.type) {
		case AvailabilityActionTypes.PARAMS:
			return {
				...state,
				loading: true,
				error: '',
			}
		case AvailabilityActionTypes.LIST:
			return {
				...state,
				availability: action.list ? action.list.items : [],
				pagination: action.list ? action.list.pagination : null,
				loading: false,
			}
		case AvailabilityActionTypes.ADD:
			return {
				...state,
				availability: [...state.availability, action.item],
				loading: false,
			}
		case AvailabilityActionTypes.UPDATE:
			const index = state.availability.findIndex((f: any) => f.id === action.item.id);
			return {
				...state,
				availability: [...state.availability.slice(0, index),
				action.item,
				...state.availability.slice(index + 1)
				],
				loading: false,
			}
		case AvailabilityActionTypes.UPDATE_BLANK:
			const index_blank = state.availability.findIndex((f: any) => f.blank_id === action.item.blank_id);
			return {
				...state,
				availability: [...state.availability.slice(0, index_blank),
				action.item,
				...state.availability.slice(index_blank + 1)
				],
				loading: false,
			}
		case AvailabilityActionTypes.DELETE:
			return {
				availability: [...state.availability.filter( (val: any) => val.id !== action.item.id )],
				loading: false,
				error: '',
				pagination: state.pagination
			}
		case AvailabilityActionTypes.DELETE_BLANK:
			return {
				availability: [...state.availability.filter( (val: any) => val.blank_id !== action.item.blank_id )],
				loading: false,
				error: '',
				pagination: state.pagination
			}
		case AvailabilityActionTypes.FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			}
		default:
			return state;
	}
}