import { Pagination } from 'src/app/models';
import { ExperienceAction } from '../action/experience.actions';
import { ExperienceActionTypes } from '../action/experience.actions';

export const featureKey = 'experience';

export interface State {
	experience: any,
	pagination: Pagination | null,
	loading: boolean,
	error: any
}

export const initialState: State = {
	experience: null,
	pagination: null,
	loading: false,
	error: ''
};


export function reducer(state = initialState, action: ExperienceAction | any): State {
	switch (action.type) {
		case ExperienceActionTypes.PARAMS:
			return {
				...state,
				loading: true,
				error: '',
			}
		case ExperienceActionTypes.LIST:
			return {
				...state,
				experience: action.list ? action.list.items : [],
				pagination: action.list ? action.list.pagination : null,
				loading: false,
			}
		case ExperienceActionTypes.ADD:
			return {
				...state,
				experience: [action.item, ...state.experience],
				loading: false,
			}
		case ExperienceActionTypes.UPDATE:
			const index = state.experience.findIndex((f: any) => f.id === action.item.id);
			return {
				...state,
				experience: [...state.experience.slice(0, index),
				action.item,
				...state.experience.slice(index + 1)
				],
				loading: false,
			}
		case ExperienceActionTypes.DELETE:
			return {
				experience: [...state.experience.filter( (val: any) => val.id !== action.item.id )],
				loading: false,
				error: '',
				pagination: state.pagination
			}
		case ExperienceActionTypes.FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			}
		default:
			return state;
	}
}