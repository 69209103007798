import { Action } from '@ngrx/store';
import { Education } from 'src/app/models';

export enum ExpertiseActionTypes {
    PARAMS = '[EXPERTISE_PARAMS] Expertise List Params',
    LIST = '[EXPERTISE_LIST] Expertise List',
    ADD = '[EXPERTISE_ADD] Expertise Add',
    UPDATE = '[EXPERTISE_UPDATE] Expertise Update',
    DELETE = '[EXPERTISE_DELETE] Expertise Detele',
    FAILURE = '[EXPERTISE_FAILURE] Expertise failed',
}

export class Params implements Action {
    readonly type = ExpertiseActionTypes.PARAMS;
    constructor(public method: string, public params?: any, public params2?: any, public key?: string) { }
}

export class List implements Action {
    readonly type = ExpertiseActionTypes.LIST;
    constructor(public list: any) { }
}

export class Add implements Action {
    readonly type = ExpertiseActionTypes.ADD;
    constructor(public item: Education) { }
}

export class Update implements Action {
    readonly type = ExpertiseActionTypes.UPDATE;
    constructor(public item: Education) { }
}

export class Delete implements Action {
    readonly type = ExpertiseActionTypes.DELETE;
    constructor(public item: any) { }
}

export class failure implements Action {
    readonly type = ExpertiseActionTypes.FAILURE;
    constructor(public error: any) { }
}


export type ExpertiseAction = Params | List | Add | Update | Delete | failure;