import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FacebookService, InitParams } from 'ngx-facebook';
import { CategoriesEnum, Role } from 'src/app/enums';
import { User } from 'src/app/models';
import { AuthService, GlobalService } from 'src/app/services';
import { AuthActions } from 'src/app/store/action';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ConfirmDialogService } from 'src/app/modules';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	isCollapsed = true;
	isLoggedIn: Observable<boolean> = this.authService.authenticationState;
	user: Observable<User> = this.gs.store.select(state => state.auth.auth);
	categories: Observable<any> = this.gs.store.select(state => state.default.categories);
	role = Role;
	form!: FormGroup;
	submitted = false;
	@ViewChild('instagaram') instagaram!: ElementRef;
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private authService: AuthService,
		public gs: GlobalService,
		private router: Router,
		private route: ActivatedRoute,
		private fb: FacebookService,
		private confirmDialog: ConfirmDialogService,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		if (isPlatformBrowser(this.platformId)) {
			//console.log('isPlatformBrowser(this.platformId)', isPlatformBrowser(this.platformId), this.platformId)
			const initParams: InitParams = {
				appId: '294952795522058',
				status: true,
				xfbml: true,
				version: 'v2.9'
			};

			this.fb.init(initParams);
		}

	}

	ngOnInit(): void {

		// this.fb.api("/me").then(res => {
		// 	console.log('res', res);
		// })
		// .catch(e => console.log(e));

		this.form = this.gs.formBuilder.group({
			q: new FormControl(''),
			category_id: new FormControl(CategoriesEnum.Electronics, [Validators.required]),
		});
		this.route.queryParams.subscribe(params => {
			this.form.patchValue({
				q: params["q"],
				category_id: params["category_id"],
			});
		});


	}

	logout = () => {
		this.gs.store.dispatch(new AuthActions.loginLogout());
	}

	categoryType(item: any) {
		let type = '';
		switch (item.id) {
			case CategoriesEnum.Electronics:
				type = 'electronics';
				break;
			case CategoriesEnum.General:
				type = 'general';
				break;
			case CategoriesEnum.Motors:
				type = 'motors';
				break;
			case CategoriesEnum.RealEstate:
				type = 'real-estate';
				break;
			default:
				break;
		}
		return type;
	}

	search(form: any) {
		if (!form.valid) {
			this.submitted = false;
			this.gs.validateAllFormFields(this.form);
			return;
		}
		let navigationExtras: NavigationExtras = {
			queryParams: {
				"q": form.value.q,
				"category_id": form.value.category_id
			}
		};
		this.router.navigate([`/search/product/type/${form.value.category_id}`], navigationExtras);
	}

	alert() {
		//alert('Coming Soon...')
		this.confirmDialog.confirmAlert("Coming Soon..", () => {

		}, () => {
			console.log('No clicked');
		});
	}

}
