export class ProductsAttributes {
    field_id!: number;
    user_id!: number;
    section_id!: number;
    moduleId!: string;
    field!: string;
    type!: string;
    name!: string;
    label!: string;
    hint!: string;
    value!: string;
    selected!: string;
    class!: string;
    parameters!: string;
    validations!: string;
    order_by!: string;
    status!: number;
    created_at!: number;
    updated_at!: number;
    options!: AttributesOptions[]
}

export class AttributesOptions {
    option_id!: number;
    user_id!: number;
    field_id!: number;
    option_code!: string;
    option_value!: string;
    sort_order!: string
}