import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ConfirmDialogModule } from '../modules/confirm-dialog/confirm-dialog.module';
import { LaddaModule } from 'src/app/modules/ladda/ladda.module';
import { ToastGlobalModule } from '../modules/toast-global/toast-global.module';

import { BaseComponent } from './components/base/base.component';
import { ProfileAvatarComponent } from './components/profile-avatar/profile-avatar.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { TruncatePipe } from './pipe/truncate.pipe';
import { SearchPipe } from './pipe/search-pipe';
import { BannerAvatarComponent } from './components/banner-avatar/banner-avatar.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SocialShareDirective } from './directive/social-share.directive';
import { BookingBoxComponent } from './components/booking-box/booking-box.component';
import { RouterModule } from '@angular/router';
import { CurrencyFormatterDirective } from './directive/currency-formatter.directive';
import { ReadMoreComponent } from './components/read-more/read-more.component';

@NgModule({
	declarations: [
		TruncatePipe,
		SearchPipe,
		FileUploaderComponent,
		BaseComponent,
		ProfileAvatarComponent,
		BannerAvatarComponent,
		PaginationComponent,
		SocialShareDirective,
		BookingBoxComponent,
		CurrencyFormatterDirective,
  ReadMoreComponent,
	],
	imports: [
		CommonModule,
		ToastGlobalModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		NgbModule,
		LaddaModule,
		ConfirmDialogModule,
		NgxSkeletonLoaderModule.forRoot()
	],
	exports: [
		TruncatePipe,
		SearchPipe,
		FileUploaderComponent,
		ProfileAvatarComponent,
		BannerAvatarComponent,
		PaginationComponent,
		SocialShareDirective,
		BookingBoxComponent,
		CurrencyFormatterDirective,
		ReadMoreComponent
	],
	providers: []
})
export class SharedModule { }


