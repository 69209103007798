import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-read-more',
	templateUrl: './read-more.component.html',
	styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements OnInit {
	@Input() public text: any;
	@Input() public maxLength: number = 100;
	@Input() public linkView: any = null;
	description: any;
	hideToggle: boolean = true;
	isCollapsed: boolean = true;

	constructor(
		private elementRef: ElementRef,
		private router: Router
	) { }

	ngOnInit(): void {

	}

	toggleView() {
		this.isCollapsed = !this.isCollapsed;
		this.determineView();
	}

	determineView() {
		if (!this.text || this.text.length <= this.maxLength) {
			this.description = this.text;
			this.isCollapsed = false;
			this.hideToggle = true;
			return;
		}
		this.hideToggle = false;
		if (this.isCollapsed == true) {
			this.description = this.text.substring(0, this.maxLength) + "...";
		} else if (this.isCollapsed == false) {
			this.description = this.text;
		}
	}

	ngOnChanges() {
		this.determineView();
		//this.priview();
	}

}
