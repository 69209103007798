export class Transactions {
    admin_fee!: number;
    commission_rate!: string;
    created_at!: number;
    is_public!: any;
    no_recipients!: any;
    name!: any;
    module!: any;
    order_id!: any;
    credit!: string;
    email!: string;
    receipt!: string;
    response_data!: any;
    stripe_fee!: any;
    total_amount!: any;
    transactionID!: any;
    transfer_amount!: number;
    coach_name!: any;
}