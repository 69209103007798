import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	
	constructor(
		private authService: AuthService,
		private router: Router,
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

			if (this.authService.isAuthenticated()) {
				return true;
			}

			if(this.authService.isTokenExpired()) {
				this.authService.logout();
				this.router.navigate(['/login']);
			}
		  
			this.router.navigate(['/login']);
		  
			return false;
	}

}
