import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ConfirmDialogService {
	private subject = new Subject<any>();
	constructor() { }

	confirmThis(message: string, yesFn: () => void, noFn: () => void): any {
		this.setConfirmation(message, yesFn, noFn);
	}

	confirmAlert(message: string, yesFn: () => void, noFn: () => void): any {
		this.setConfirmation(message, yesFn, noFn, 'alert');
	}

	setConfirmation(message: string, yesFn: () => void, noFn: () => void, type: any = 'confirm'): any {
		const that = this;
		this.subject.next({
			type: type,
			text: message,
			yesFn(): any {
				that.subject.next(); // This will close the modal  
				yesFn();
			},
			noFn(): any {
				that.subject.next();
				noFn();
			}
		});

	}

	getMessage(): Observable<any> {
		return this.subject.asObservable();
	}
}
