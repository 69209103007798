import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as ExperienceReducer from '../reducer/experience.reducer';

const getState = createFeatureSelector<ExperienceReducer.State>(
    ExperienceReducer.featureKey
);

export const experiences = createSelector(
    getState,
    state => state.experience
);

export const loading = createSelector(
    getState,
    state => state.loading
);