<div class="profileimage d-flex align-items-center">
    <div class="circle">
        <!-- User Profile Image -->
        <img class="profile-pic" *ngIf="profile | async as user" [src]="user.avatar ? user.avatar : '/assets/images/avatar.webp'" alt="" />
        <!-- Default Image -->
        <!-- <i class="fa fa-user fa-5x"></i> -->
        <div class="loader rounded-circle" *ngIf="loading"><span></span> <span></span></div>
    </div>
    <label class="btn btn-primary">
        <img src="/assets/images/svg/camera.svg" class="upload-button mr-2" alt="" for="avatar"> Change Profile Photo
        <input class="file-upload" type="file" accept="image/*" (change)="upload($event)" id="avatar" /> 
    </label>
</div>