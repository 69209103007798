import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse, User } from '../models';
import { ToastService } from '../modules/toast-global/toast.service';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {
	public errors: any = [];
	public mainTitle = "Reakt3";
	public identity: User;
	public uploadPath = environment.uploadPath;
	store = this.stores;
	formBuilder = this.fb;
	activeRoute = this.activeRoutes;

	constructor(
		private linkRouter: Router,
		private toastService: ToastService,
		private authService: AuthService,
		private titleService: Title,
		private stores: Store<any>,
		private fb: FormBuilder,
		private activeRoutes: ActivatedRoute,
	) {
		const user: any = localStorage.getItem('user');
		this.identity = (user !== null) ? JSON.parse(user) : null;
	}

	clearErrorMessages() {
		this.errors = [];
	}

	handleHttpError(error: HttpErrorResponse) {
		return throwError(error || 'Server Error');
	}

	ObjectToArray(objs: any) {
		const items: any = [];
		objs && Object.keys(objs).map(itemKey => {
			const item = objs[itemKey];
			items.push(item);
		});
		return items;
	};

	public handleErrors(error: any) {
		const httpErrorCode = error.status;
		//console.log('httpErrorCode', httpErrorCode, error);
		this.clearErrorMessages();
		switch (httpErrorCode) {
			case 401:
				this.authService.logout();
				this.linkRouter.navigateByUrl('/login');
				this.toastService.show(error.error.message, { classname: 'bg-warning' });
				break;
			case 403:
				//this.linkRouter.navigateByUrl('/login');
				this.toastService.show(error.error.message, { classname: 'bg-danger' });
				break;
			case 422:
				//console.log('error.error.data', error.error.data);
				const messages: any = error.error.data;
				const messages2: any = error.error.data?.errors;
				const errors = (messages === null) ? error.error?.message : messages?.errors.join(',');
				for (const message in messages) {
					if (messages[message]) {
						messages[message].forEach((element: any) => {
							this.errors.push(element[0]);
						});
						//this.errors.push(messages[message].join(''));
					}
				}
				for (const message in messages2) {
					if (messages[message]) {
						messages[message].forEach((element: any) => {
							this.errors.push(element[0]);
						});
						//this.errors.push(messages[message].join(''));
					}
				}
				this.toastService.show(errors, { classname: 'bg-danger' });
				break;
			case 400:
				this.toastService.show(error.error.message, { classname: 'bg-danger' });
				break;
			case 404:
				this.toastService.show(error.error.message, { classname: 'bg-danger' });
				break;
			case 500:
				this.toastService.show(error.error.message, { classname: 'bg-danger' });
				break;
			case 0:
				//console.log('error', error, error.message);
				this.toastService.show(error.message, { classname: 'bg-danger' });
				break;
			default:
				this.toastService.show('An error occurred while processing your request.', { classname: 'bg-danger' });
		}
	}

	alert(msg: any, cls = 'success') {
		this.toastService.show(msg, { classname: `bg-${cls}` });
	}

	setTitle(title: any) {
		this.titleService.setTitle(this.mainTitle + ' | ' + title);
	}

	router(url: string) {
		this.linkRouter.navigate([`${url}`]);
	}

	capitalize(s: any) {
		let space = s.split('.').join(' ');
		return space && space.charAt().toUpperCase() + space.slice(1);
	}

	generateFake(count: number): Array<number> {
		const indexes = [];
		for (let i = 0; i < count; i++) {
			indexes.push(i);
		}
		return indexes;
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			} else if (control instanceof FormArray) {
				control.markAllAsTouched();
			}
		});
	}

	isValid(fieldName: any, form?: any, group?: string | undefined): boolean {
		let field = form && form[fieldName] && form[fieldName].invalid && (form[fieldName].dirty || form[fieldName].touched);
		let formGroup = group && form.get(group)?.get(fieldName)?.invalid && (form.get(group)?.get(fieldName)?.dirty || form.get(group)?.get(fieldName)?.touched);
		return (group === undefined || group === null || group === '') ? field : formGroup;
	}

	addCls(fieldName: string, form?: any, group?: string | undefined) {
		let field = { 'is-invalid': this.isValid(fieldName, form, group), 'has-feedback': this.isValid(fieldName, form, group) };
		let formGroup = { 'is-invalid': this.isValid(fieldName, form, group), 'has-feedback': this.isValid(fieldName, form, group) };
		return (group === undefined || group === null || group === '') ? field : formGroup;
	}

	validationMsg(fieldName: string, form?: any, group?: string | undefined) {
		let field = `${this.capitalize(fieldName)} is requared`;
		let formGroup = `${this.capitalize(fieldName)} is requared`;
		//let 

		return (group === undefined || group === null || group === '') ? field : formGroup;
	}

	files(event: any, form: any) {
		form.patchValue({ files: event })
	}

	apiResponce(item: ApiResponse) {
		let code = item.code;
		let data = item.data;
		let message = item.message;
		let status = item.status;
		let items = data ? data.items : [];
		let pagination = data ? data.pagination : null;
		let model = data?.model ? data?.model : null;
		return { code: code, message: message, status: status, data: data, model: model, items: items, pagination: pagination }
	}

	yearList() {
		const current_year = new Date().getFullYear();
		const indexes = [];
		for (let i = current_year; i > 1991; i--) {
			indexes.push(i);
		}
		return indexes;
	}

	bookingSlot() {
		return 60;
	}

}
