<!-- {{files | async | json}} -->
<div class="form-group">
    <label [innerHtml]="title"></label>
    <ngb-progressbar *ngIf="loading | async" type="success" textType="white" [value]="100" [showValue]="false" [striped]="true" [animated]="true"></ngb-progressbar>
    <div class="custom-file loader-file">
        <input type="file" class="custom-file-input" id="customFile1" [accept]="accept" multiple (change)="upload($event)">
        <!-- <input *ngIf="!multiple" type="file" class="custom-file-input" id="customFile1" [accept]="accept" (change)="upload($event)"> -->
        <label class="custom-file-label" for="customFile1">
            <i class="fas fa-plus-circle" aria-hidden="true"></i>
            <!-- Upload File -->
        </label>
    </div>
</div>
<ul *ngIf="(files | async)?.image" class="images">
    <li *ngFor="let file of (files | async)?.image">
        <img [src]="file.path" alt="Upload {{file.id}}" />
        <button type="button" class="btn btn-danger btn-sm" (click)="remove(file.id)"><i class="far fa-trash-alt"></i></button>
    </li>
</ul>

<ul *ngIf="(files | async)?.docs" class="docs">
    <li *ngFor="let file of (files | async)?.docs">
        <div class="col pl-0 d-flex align-items-center">
            <i *ngIf="file.mimetype === 'application/pdf'" class="far fa-file-pdf"></i>
            <i *ngIf="file.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" class="far fa-file-word"></i>
            <i *ngIf="(file.mimetype !== 'application/pdf') && (file.mimetype !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')" class="far fa-file"></i>
            <span class="title pl-2">{{file.filename}}</span>
        </div>
        <button type="button" class="btn btn-danger btn-sm" (click)="remove(file.id)"><i class="far fa-trash-alt"></i></button>
    </li>
</ul>

<div className="image-list" *ngIf="(files | async)?.video">
    <div className="row">
        <div className="col-sm-3 col-md-4 col-sm-6 col-12" *ngFor="let item of (files | async)?.video">
            <div className="image-box">
                <video style="max-height: 170px; width: 100%" controls>
                    <source [src]="item.path" [type]="item.type" /> 
                    Your browser does not support the video tag. 
                </video>
                <button type="button" class="btn btn-danger btn-sm" (click)="remove(item.id)"><i class="far fa-trash-alt"></i></button>
            </div>
        </div>
    </div>
</div>
