import { Action, createReducer, on } from '@ngrx/store';
import { User } from 'src/app/models/User';
import { ItemAction, ItemActionTypes } from '../action/item.action'; 

export const featureKey = 'item';

export interface State {
	staffs: User[] | null,
	courts: any,
	loading: boolean,
	error: any
}

export const initialState: State = {
	staffs: null,
	courts: null,
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: ItemAction | any): State {

	switch (action.type) {
		case ItemActionTypes.ITEM_STAFF_PARAMS:
			return {
				...state,
				staffs: null,
				loading: true,
				error: ''
			}
		case ItemActionTypes.ITEM_STAFFS:
			return {
				...state,
				staffs: action.staffs,
				loading: false,
			}
		case ItemActionTypes.ITEM_FAILURE:
			return {
				...state,
				staffs: action.error,
				loading: false,
				error: ''
			}
		default:
			return state;
	}
};