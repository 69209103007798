import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ApiResponse} from 'src/app/models';
import {GlobalService} from 'src/app/services/global.service';
import {ItemService} from 'src/app/services/item.service';
import {ProductsAction} from '../action';

@Injectable()
export class ProductsEffects {
  constructor(
    private actions$: Actions,
    private itemService: ItemService,
    private gs: GlobalService,
  ) {
  }

  productAttribute$ = createEffect(() => this.actions$.pipe(
    ofType(ProductsAction.ProductsActionTypes.PRODUCTS_ATTRIBUTE_PARAMS),
    mergeMap((options: ProductsAction.AttributeParams) =>
      this.itemService.productsAttributes("GET", options.options.params, options.options.params2).pipe(
        map((item: ApiResponse) => {
          return new ProductsAction.Attributes(item.data?.items ? item.data.items : []);
        }),
        catchError((error) => {
          this.gs.handleErrors(error);
          return [new ProductsAction.failure(error)]
        })
      ))
  ));

  productFields$ = createEffect(() => this.actions$.pipe(
    ofType(ProductsAction.ProductsActionTypes.PRODUCTS_FIELDS_PARAMS),
    mergeMap((options: ProductsAction.FieldsParams) =>
      this.itemService.productsFields("GET", options.options.params, options.options.params2).pipe(
        map((item: ApiResponse) => {
          return new ProductsAction.Fields(item.data?.items ? item.data.items : []);
        }),
        catchError((error) => {
          this.gs.handleErrors(error);
          return [new ProductsAction.failure(error)]
        })
      ))
  ));

  productOptions$ = createEffect(() => this.actions$.pipe(
    ofType(ProductsAction.ProductsActionTypes.PRODUCTS_OPTIONS_PARAMS),
    mergeMap((options: ProductsAction.OptionsParams) =>
      this.itemService.productsOptions("GET", options.options.params, options.options.params2).pipe(
        map((item: ApiResponse) => {
          return new ProductsAction.Options(item.data?.items ? item.data.items : []);
        }),
        catchError((error) => {
          this.gs.handleErrors(error);
          return [new ProductsAction.failure(error)]
        })
      ))
  ));

  productParams$ = createEffect(() => this.actions$.pipe(
    ofType(ProductsAction.ProductsActionTypes.PRODUCTS_MY_LIST_PARAMS),
    mergeMap((params: ProductsAction.MyProductParams) =>
      this.itemService.products(params.method, params?.params, params?.params2).pipe(
        map((response: any) => {
          const key = params?.key;
          let data = this.gs.apiResponce(response);
          let actionResponse = null;
          switch (key) {
            case 'more':
              actionResponse = new ProductsAction.MyProductsMore(data.data ? data.items : []);
              break;
            case 'view':
              actionResponse = new ProductsAction.View(data.data);
              break;
            case 'active':
              actionResponse = new ProductsAction.ActiveList(data.data);
              break;
            case 'active-more':
              actionResponse = new ProductsAction.ActiveListMore(data.data ? data.items : []);
              break;
            case 'inactive':
              actionResponse = new ProductsAction.InactiveList(data.data);
              break;
            case 'inactive-more':
              actionResponse = new ProductsAction.InactiveListMore(data.data ? data.items : []);
              break;
            case 'current':
              actionResponse = new ProductsAction.CurrentAuctions(data.data);
              break;
            case 'current-more':
              actionResponse = new ProductsAction.CurrentAuctionsMore(data.data ? data.items : []);
              break;
            case 'past':
              actionResponse = new ProductsAction.PastAuctions(data.data);
              break;
            case 'past-more':
              actionResponse = new ProductsAction.PastAuctionsMore(data.data ? data.items : []);
              break;
            case 'create_product':
              this.gs.alert('You have successfully created product.', 'success');
              this.gs.router('/coach/my-listings/listing/actives');
              actionResponse = new ProductsAction.Add(data.data, 'create_product');
              break;
            case 'update_product':
              this.gs.alert('You have successfully update product.', 'success');
              this.gs.router('/coach/my-listings/listing/actives');
              actionResponse = new ProductsAction.Update(data.data, 'update_product');
              break;
            case 'create_auction':
              this.gs.alert('You have successfully created auction.', 'success');
              this.gs.router('/coach/my-auctions');
              actionResponse = new ProductsAction.Add(data.data, 'create_auction');
              break;
            case 'update_auction':
              this.gs.alert('You have successfully update auction.', 'success');
              this.gs.router('/coach/my-auctions');
              actionResponse = new ProductsAction.Update(data.data, 'update_auction');
              break;

            default:
              actionResponse = new ProductsAction.MyProducts(data.data);
              break;
          }
          return actionResponse;
        }),
        catchError((error) => {
          this.gs.handleErrors(error);
          return [new ProductsAction.failure(error)]
        })
      ))
  ));


  productSearch$ = createEffect(() => this.actions$.pipe(
    ofType(ProductsAction.ProductsActionTypes.PRODUCTS_SEARCH_PARAMS),
    mergeMap((options: ProductsAction.ProductSearchParams) =>
      this.itemService.products("GET", null, options.params).pipe(
        map((response: ApiResponse) => {
          let data = this.gs.apiResponce(response);
          return new ProductsAction.ProductSearch(data?.items ? data.items : [], data.pagination ? data.pagination : null);
        }),
        catchError((error) => {
          this.gs.handleErrors(error);
          return [new ProductsAction.failure(error)]
        })
      ))
  ));

  featuredProduct$ = createEffect(() => this.actions$.pipe(
    ofType(ProductsAction.ProductsActionTypes.PRODUCTS_FEATURED_LIST_PARAMS),
    mergeMap((options: ProductsAction.FeaturedProductsParams) =>
      this.itemService.products("GET", null, options.params).pipe(
        map((response: ApiResponse) => {
          let data = this.gs.apiResponce(response);
          return new ProductsAction.ProductSearch(data?.items ? data.items : [], data.pagination ? data.pagination : null);
        }),
        catchError((error) => {
          this.gs.handleErrors(error);
          return [new ProductsAction.failure(error)]
        })
      ))
  ));

  productWishlist$ = createEffect(() => this.actions$.pipe(
    ofType(ProductsAction.ProductsActionTypes.PRODUCTS_WISHLIST_LIST_PARAMS),
    mergeMap((options: ProductsAction.wishlistParams) =>
      this.itemService.wishlist(options.method, options?.param, options?.param2)
        .pipe(
          map((response: ApiResponse) => {
            let data = this.gs.apiResponce(response);
            if (options.key === 'wishlist-list') {
              if (options.method === 'DELETE') {
                data?.items.filter((item: { item_id: any; }) => (item.item_id !== options.item.item_id));
              }
              return (new ProductsAction.wishlist(data?.items ? data.items : [], data?.pagination ? data?.pagination : null));
            }
            return (new ProductsAction.Update(
              (options.method == 'DELETE' && options.key === 'wishlist') ?
                ({item: options.item}) :
                (data?.data ? data.data : []), options.key));
          }),
          catchError((error) => {
            this.gs.handleErrors(error);
            return [new ProductsAction.failure(error)]
          })
        ))
  ));


}


