import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate {

	constructor(
		private authService: AuthService,
		private router: Router,
	) { }

	canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.authService.isAuthenticated()) {
			const currentUser = this.authService.userRole(); 
			const role        = route.data.role; 	
			//console.log('role', role, role.indexOf(currentUser) > -1, currentUser);
            if(this.authService.isTokenExpired()) {
                this.authService.logout();
                this.router.navigate(['/login']);
            }	    
			if(role && role.indexOf(currentUser) > -1) {
				return true;
			} else  {
				return false;    
            }
		}
		this.router.navigate(['/login']);
		return false;
	}

}
