import { UploadAction, UploadActionTypes } from "../action/upload.actions";

export const featureKey = 'upload';

export interface State {
	files: any,
	progress: any,
	loading: boolean,
	error: any
}

export const initialState: State = {
	files: {
        image: [],
        video: [],
        docs: []
    },
	loading: false,
	progress: 0,
	error: ''
};

export function reducer(state = initialState, action: UploadAction | any): State {
    switch (action.type) {
        case UploadActionTypes.UPLOAD_PARAMS:
			return {
				...state,
				loading: true,
				error: '',
			}
        case UploadActionTypes.UPLOAD_PROGRESS:
            return {
                ...state,
                progress: action.progress
            }
        case UploadActionTypes.UPLOAD_RESPOND:
            return {
                ...state,
                files: action.files,
                loading: false,
                progress: 0
            }
        case UploadActionTypes.UPLOAD_CLEAR:
            return {
                ...state,
                files: {}
            }
        case UploadActionTypes.UPLOAD_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			}
        default:
			return state;
    }
}