export enum Status {
    ORDER_PENDING = 1,
    ORDER_PROCESSING = 2,
    ORDER_SHIPPED = 3,
    ORDER_DENIED = 4,
    ORDER_CANCELLED = 5,
    ORDER_FAILED = 6,
    ORDER_REFUNDED = 7,
    ORDER_REVERSED = 8,
    ORDER_VOIDED = 9,
    ORDER_EXPIRED = 10,
    ORDER_ACCEPTED = 11,
    ORDER_ACCEPTED_AND_CLOSED = 12,
    ORDER_DECLINED = 13,
    ORDER_MARK_AS_COMPLETE = 14,
    ORDER_NOT_COMPLETE = 15,
    ORDER_DISPUTE = 16,
    ORDER_DISPUTE_PROCESSING = 17,
    ORDER_DISPUTE_INVESTIGATION = 18,
    ORDER_DISPUTE_CANCELED = 19,
    ORDER_DISPUTE_RESOLVED = 20,
    ORDER_COMPLETE = 21,
    ORDER_OFFERS = 23,
    ORDER_COUNTER_OFFERS = 24,
    ORDER_PAYMENT_CONFIRM = 25,
    ORDER_PAYMENT_TRANSFER = 26,
    ORDER_RETURN = 27,
    ORDER_PICK_UP = 28,
    ORDER_DELIVERY = 29,
    ORDER_DELIVERED = 30,

    // ORDER_ITEM_STATUS_NONE, ORDER_ITEM_STATUS_ACTIVE, ORDER_ITEM_STATUS_PENDING, ORDER_ITEM_STATUS_COMPLETE, ORDER_ITEM_STATUS_AWAITING_PRODUCTS, ORDER_ITEM_STATUS_START, ORDER_ITEM_STATUS_STOP

    ORDER_ITEM_STATUS_NONE = 0, // self::ORDER_ITEM_STATUS_ACTIVE => 'None',
    ORDER_ITEM_STATUS_ACTIVE = 1, // self::ORDER_ITEM_STATUS_ACTIVE => 'Active',
    ORDER_ITEM_STATUS_PENDING = 2, // self::ORDER_ITEM_STATUS_PENDING => 'Pending',
    ORDER_ITEM_STATUS_COMPLETE = 3, // self::ORDER_ITEM_STATUS_COMPLETE => 'Complete',
    ORDER_ITEM_STATUS_AWAITING_PRODUCTS = 4, // self::ORDER_ITEM_STATUS_AWAITING_PRODUCTS => 'Awaiting Products',
    ORDER_ITEM_STATUS_START = 5, // self::ORDER_ITEM_STATUS_START => 'Start',
    ORDER_ITEM_STATUS_STOP = 6, // self::ORDER_ITEM_STATUS_STOP => 'Stop'

    // ORDER_ITEM_REASON_NONE, ORDER_ITEM_REASON_DEAD, ORDER_ITEM_REASON_RECEIVED, ORDER_ITEM_REASON_ERROR, ORDER_ITEM_REASON_FAULTY, ORDER_ITEM_REASON_OTHER

    ORDER_ITEM_REASON_NONE = 0,  // self::ORDER_ITEM_REASON_NONE => 'None',
    ORDER_ITEM_REASON_DEAD = 1,  //  self::ORDER_ITEM_REASON_DEAD => 'Dead On Arrival',
    ORDER_ITEM_REASON_RECEIVED = 2, //  self::ORDER_ITEM_REASON_RECEIVED => 'Received Wrong Item',
    ORDER_ITEM_REASON_ERROR = 3,  // self::ORDER_ITEM_REASON_ERROR => 'Order Error',
    ORDER_ITEM_REASON_FAULTY = 4, // self::ORDER_ITEM_REASON_FAULTY => 'Faulty, please supply details',
    ORDER_ITEM_REASON_OTHER = 5,  // self::ORDER_ITEM_REASON_OTHER => 'Other, please supply details',

    // ORDER_ITEM_ACTION_NONE, ORDER_ITEM_ACTION_RETURN, ORDER_ITEM_ACTION_ISSUED, ORDER_ITEM_ACTION_REPLACEMENT
    
    ORDER_ITEM_ACTION_NONE = 0, // None
    ORDER_ITEM_ACTION_RETURN = 1, // Refunded/Return
    ORDER_ITEM_ACTION_ISSUED = 2, // Credit Issued
    ORDER_ITEM_ACTION_REPLACEMENT = 3, // Replacement Sent

    // IS_OPENED_YES, IS_OPENED_NO
    IS_OPENED_YES = 1,
    IS_OPENED_NO = 0,
}

export const is_opened_array = () => {
    return [
        'No',
        'Yes',
    ]
};

export const order_status_array = () => {
    return [
        'None',
        'Pending',
        'Processing',
        'Shipped',
        'Denied',
        'Cancelled',
        'Failed',
        'Refunded',
        'Reversed',
        'Voided',
        'Expired',
        'Accepted',
        'Accepted And Closed',
        'Declined',
        'Mark As Complete',
        'Not Complete',
        'Dispute',
        'Dispute Processing',
        'Dispute Investigation',
        'Dispute Canceled',
        'Dispute Resolved',
        'Complete',
        'Offers',
        'Counter Offers',
        'Payment Confirm',
        'Payment Transfer',
        'Return',
        'Pick Up',
        'Delivery',
        'Delivered',
    ]
};

export const item_status_array = () => {
    return [
        'None',
        'Active',
        'Pending',
        'Complete',
        'Awaiting Products',
        'Start',
        'Stop',
    ]
};

export const reason_array = () => {
    return [
        'None',
        'Dead On Arrival',
        'Received Wrong Item',
        'Order Error',
        'Faulty, please supply details',
        'Other, please supply details',
    ]
};

export const action_array = () => {
    return [
        'None',
        'Refunded/Return',
        'Credit Issued',
        'Replacement Sent',
    ]
};


export const item_status = (item: any) => {
    let statusTitle = '';
    let cls = '';
    switch (item.status) {
        case Status.ORDER_ITEM_STATUS_NONE:
            statusTitle = 'None';
            cls = 'primary';
            break;
        case Status.ORDER_ITEM_STATUS_ACTIVE:
            statusTitle = 'Active';
            cls = 'info';
            break;
        case Status.ORDER_ITEM_STATUS_PENDING:
            statusTitle = 'Pending';
            cls = 'warning';
            break;
        case Status.ORDER_ITEM_STATUS_COMPLETE:
            statusTitle = 'Complete';
            cls = 'success';
            break;
        case Status.ORDER_ITEM_STATUS_AWAITING_PRODUCTS:
            statusTitle = 'Awaiting Products';
            cls = 'primary';
            break;
        case Status.ORDER_ITEM_STATUS_START:
            statusTitle = 'Start';
            cls = 'success';
            break;
        case Status.ORDER_ITEM_STATUS_STOP:
            statusTitle = 'Stop';
            cls = 'danger';
            break;
        default:
            break;
    }
    return { title: statusTitle, cls: cls };
}

export const order_status = (item: any) => {
    let statusTitle = '';
    let cls = '';
    switch(item.status) {
        case Status.ORDER_PENDING:
            statusTitle = 'In-Progress';
            cls = 'info';
            break;
        case Status.ORDER_PROCESSING:
            statusTitle = 'Processing';
            cls = 'info';
            break;
        case Status.ORDER_SHIPPED:
            statusTitle = 'Shipped';
            cls = 'success';
            break;
        case Status.ORDER_DENIED:
            statusTitle = 'Denied';
            cls = 'danger';
            break;
        case Status.ORDER_CANCELLED:
            statusTitle = 'Cancelled';
            cls = 'danger';
            break;
        case Status.ORDER_FAILED:
            statusTitle = 'Failed';
            cls = 'danger';
            break;
        case Status.ORDER_REFUNDED:
            statusTitle = 'Pending';
            cls = 'warning';
            break;
        case Status.ORDER_REVERSED:
            statusTitle = 'Reversed';
            cls = 'warning';
            break;
        case Status.ORDER_VOIDED:
            statusTitle = 'Voided';
            cls = 'warning';
            break;
        case Status.ORDER_DELIVERY:
            statusTitle = 'Delivery';
            cls = 'info';
            break;
        case Status.ORDER_DELIVERED:
            statusTitle = 'Delivered';
            cls = 'success';
            break;
        case Status.ORDER_DECLINED:
            statusTitle = 'Declined';
            cls = 'danger';
            break;
        case Status.ORDER_EXPIRED:
            statusTitle = 'Expired';
            cls = 'primary';
            break;
        case Status.ORDER_ACCEPTED:
            statusTitle = 'Accepted';
            cls = 'dark';
            break;
        case Status.ORDER_PICK_UP:
            statusTitle = 'Pick Up';
            cls = 'info';
            break;
        case Status.ORDER_ACCEPTED_AND_CLOSED:
            statusTitle = 'Accepted_and_closed';
            cls = 'primary';
            break;
        case Status.ORDER_NOT_COMPLETE:
            statusTitle = 'Not Complete';
            cls = 'danger';
            break;
        case Status.ORDER_MARK_AS_COMPLETE:
            statusTitle = 'Mark As Complete';
            cls = 'primary';
            break;
        case Status.ORDER_DISPUTE:
            statusTitle = 'Dispute';
            cls = 'danger';
            break;
        case Status.ORDER_DISPUTE_PROCESSING:
            statusTitle = 'Dispute Processing';
            cls = 'danger';
            break;
        case Status.ORDER_DISPUTE_INVESTIGATION:
            statusTitle = 'Dispute Investigation';
            cls = 'danger';
            break;
        case Status.ORDER_DISPUTE_CANCELED:
            statusTitle = 'Dispute Canceled';
            cls = 'danger';
            break;
        case Status.ORDER_DISPUTE_RESOLVED:
            statusTitle = 'Dispute Resolved';
            cls = 'success';
            break;
        case Status.ORDER_RETURN:
            statusTitle = 'Return';
            cls = 'danger';
            break;
        case Status.ORDER_COMPLETE:
            statusTitle = 'Complete';
            cls = 'primary';
            break;
        case Status.ORDER_OFFERS:
            statusTitle = 'OFFERS';
            cls = 'primary';
            break;
        case Status.ORDER_COUNTER_OFFERS:
            statusTitle = 'Counter Offers';
            cls = 'primary';
            break;
        case Status.ORDER_PAYMENT_CONFIRM:
            statusTitle = 'Payment Confirm';
            cls = 'primary';
            break;
        case Status.ORDER_PAYMENT_TRANSFER:
            statusTitle = 'Payment Transfer';
            cls = 'primary';
            break;
        default:;
    }
    return {title: statusTitle, cls: cls};
}
