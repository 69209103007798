// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	hmr: false,
	apiUrl: 'https://api.reakt3.omshana.com',
	tokenName: 'token',
	hostName: 'https://www.reakt3.omshana.com',
	uploadPath: 'https://upload.reakt3.omshana.com/',
	whitelistedDomains: [
		'api.reakt3.omshana.com'
	],
	googleCaptcha: "6Lcx1l4aAAAAAM_O2v2P_9icGlTNaCjbwbV7VMpS",
	firebaseConfig: {
		apiKey: "AIzaSyD7nCoqKth6vi-ZdEUJhn_AW3HIOp1xHng",
		authDomain: "fourzlaw.firebaseapp.com",
		projectId: "fourzlaw",
		storageBucket: "fourzlaw.appspot.com",
		messagingSenderId: "1026286624484",
		appId: "1:1026286624484:web:d809278610a162f8de56f1",
		measurementId: "G-VBW4X3VF81"
	},
	STRIPE_PUBLIC_KEY: 'pk_test_51KOUtvBhY2ee8dt8oYUpP3zE6aZNPXHXJB8jamAC9DhqpNEqIGuMm3a09z7Csu8Z8cMlOst1JfEkFeuy5kAl1tS800rVQXziNe',
	STRIPE_PRIVATE_KEY: 'sk_test_51KOUtvBhY2ee8dt8wCFK8SeJcAN50n7vACx1jj3Fq3DiVmddrXfIiAaJLReyG92j0GuwmpqXr8Mwdg3gp5qIBEcn00QZWiSKDE',
	STRIPE_CLIENT_ID: 'ca_LBFhu1JZyx38nCHenRn2Jmgv36Czu4mR',

	STRIPE_REDIRECT_URI: `https://www.reakt3.omshana.com/coach/profile/payment`,
  SOCKET_URL: 'http://159.203.82.212:8888/',

	FACEBOOK_APP_ID: '294952795522058',
	FACEBOOK_APP_TOKEN: '51e283246089fa409448cf3586d1e552',
	GOOGLE_APP_ID: '690512090735-dajge01nei61ocdils1m9vg3v42im4h6.apps.googleusercontent.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
