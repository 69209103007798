import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
	@Input() pagination: any;
	@Input() page: number = 1;
	@Output() getPage = new EventEmitter();
	constructor() { }

	ngOnInit(): void {
		//console.log('pagination', this.pagination, this.page);
	}

	handlePagination(event: any) {
		//this.loadItem(event);
		this.getPage.emit(event);
	}

}


