import { Photo } from './Photo'

export class Notifications {
    message_id!: number;
    item_type!: string;
    action_id!: number;
    action!: string;
    item_id!: number;
    sender_id!: number;
    avatar!: Photo;
    notification!: string;
    text!: string;
    textJson!: object;
    ip!: string;
    url!: string;
    status!: string;
    seen!: boolean;
    created_at!: number
}