export class Location {
    id!: number;
    user_id!: number;
    name!: string;
    email!: string;
    phone_number!: string;
    address_line_1!: string;
    address_line_2!: string;
    city!: string;
    location!: string;
    latitude!: string;
    longitude!: string;
    state_code!: string;
    zip5!: string;
    country_code!: string;
    created_at!: number;
    updated_at!: number;
    is_primary!: number
    status!: number
}