import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as ExpertiseReducer from '../reducer/expertise.reducer';

const getState = createFeatureSelector<ExpertiseReducer.State>(
    ExpertiseReducer.featureKey
);

export const expertise = createSelector(
    getState,
    state => state.expertise
);

export const loading = createSelector(
    getState,
    state => state.loading
);