import { Action } from '@ngrx/store';
import { Education } from 'src/app/models';

export enum EducationsActionTypes {
    PARAMS = '[EDUCATIONS_PARAMS] Educations List Params',
    LIST = '[EDUCATIONS_LIST] Educations List',
    ADD = '[EDUCATIONS_ADD] Educations Add',
    UPDATE = '[EDUCATIONS_UPDATE] Educations Update',
    DELETE = '[EDUCATIONS_DELETE] Educations Detele',
    FAILURE = '[EDUCATIONS_FAILURE] Educations failed',
}

export class Params implements Action {
    readonly type = EducationsActionTypes.PARAMS;
    constructor(public method: string, public params?: any, public params2?: any, public key?: string) { }
}

export class List implements Action {
    readonly type = EducationsActionTypes.LIST;
    constructor(public list: any) { }
}

export class Add implements Action {
    readonly type = EducationsActionTypes.ADD;
    constructor(public item: Education) { }
}

export class Update implements Action {
    readonly type = EducationsActionTypes.UPDATE;
    constructor(public item: Education) { }
}

export class Delete implements Action {
    readonly type = EducationsActionTypes.DELETE;
    constructor(public item: any) { }
}

export class failure implements Action {
    readonly type = EducationsActionTypes.FAILURE;
    constructor(public error: any) { }
}


export type EducationsAction = Params | List | Add | Update | Delete | failure;