import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as userReducer from '../reducer/user.reducer';

// Lookup the 'Joke' feature state managed by NgRx
const getState = createFeatureSelector<userReducer.State>(
    userReducer.featureKey
);

export const transactions = createSelector(
    getState,
    state => state.transactions
);

export const transactionPagination = createSelector(
    getState,
    state => state.transactionPagination
);

export const error = createSelector(
    getState,
    state => state.error
);

export const loading = createSelector(
    getState,
    state => state.loading
);