import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as defaultReducer from '../reducer/default.reducer';

// Lookup the 'Joke' feature state managed by NgRx
const getState = createFeatureSelector<defaultReducer.State>(
    defaultReducer.featureKey
);

export const countries = createSelector(
    getState,
    state => state.countries
);

export const states = createSelector(
    getState,
    state => state.states
);

export const categories = createSelector(
    getState,
    state => state.categories
);

export const config = createSelector(
    getState,
    state => state.config
);

export const platforms = createSelector(
    getState,
    state => state.platforms
);


export const error = createSelector(
    getState,
    state => state.error
);

export const loading = createSelector(
    getState,
    state => state.loading
);