import { Action } from '@ngrx/store';

export enum UploadActionTypes {
    UPLOAD_PARAMS = '[UPLOAD_PARAMS] Params',
    UPLOAD_CLEAR = '[UPLOAD_CLEAR] Clear',
    UPLOAD_FAILURE = '[UPLOAD_FAILURE] Failure',
    UPLOAD_RESPOND = '[UPLOAD_RESPOND] Respond',
    UPLOAD_PROGRESS = '[UPLOAD_PROGRESS] Progress',
    UPLOAD_DELETE = 'UPLOAD_DELETE Delete',
}

export class Params implements Action {
    readonly type = UploadActionTypes.UPLOAD_PARAMS;
    constructor(public params?: any, public upload?: any, public key?: string | 'upload') { }
}

export class Respond implements Action {
    readonly type = UploadActionTypes.UPLOAD_RESPOND;
    constructor(public files: any) { }
}

export class Progress implements Action {
    readonly type = UploadActionTypes.UPLOAD_PROGRESS;
    constructor(public progress: any) { }
}

export class Delete implements Action {
    readonly type = UploadActionTypes.UPLOAD_DELETE;
    constructor(public item: any) { }
}

export class Clear implements Action {
    readonly type = UploadActionTypes.UPLOAD_CLEAR;
    constructor(public item?: any) { }
}

export class Failure implements Action {
    readonly type = UploadActionTypes.UPLOAD_FAILURE;
    constructor(public error: any) { }
}

export type UploadAction = Params | Respond | Progress | Delete | Clear | Failure;