import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as ordersReducer from '../reducer/order.reducer';

// Lookup the 'Joke' feature state managed by NgRx
const getState = createFeatureSelector<ordersReducer.State>(
    ordersReducer.featureKey
);

export const loading = createSelector(
    getState,
    state => state.loading
);

export const view = createSelector(
    getState,
    state => state.view
);

export const search = createSelector(
    getState,
    state => state.search
);

export const searchPagination = createSelector(
    getState,
    state => state.searchPagination
);

export const myOrders = createSelector(
    getState,
    state => state.myOrders
);

export const myPagination = createSelector(
    getState,
    state => state.myPagination
);

export const completedOrders = createSelector(
    getState,
    state => state.completedOrders
);

export const completedPagination = createSelector(
    getState,
    state => state.completedPagination
);


export const cancelledOrders = createSelector(
    getState,
    state => state.cancelledOrders
);

export const cancelledPagination = createSelector(
    getState,
    state => state.cancelledPagination
);

export const error = createSelector(
    getState,
    state => state.error
);

