import { Pagination } from 'src/app/models';
import { UserAction, UserActionTypes } from '../action/user.actions';

export const featureKey = 'user';

export interface State {
	transactions: any,
	transactionPagination: Pagination | null,
	loading: boolean,
	error: any
}

export const initialState: State = {
	transactions: null,
	transactionPagination: null,
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: UserAction | any): State {
	switch (action.type) {
		case UserActionTypes.USER_PROFILE:
		case UserActionTypes.TRANSACTIONS_PARAMS:
			return {
				...state,
				loading: true,
				error: ''
			}
		case UserActionTypes.USER_PROFILE_SUCCESS:
			return {
				...state,
				loading: false,
			}
		case UserActionTypes.TRANSACTIONS:
			return {
				...state,
				transactions: action.list ? action.list.items : [],
				transactionPagination: action.list ? action.list.pagination : null,
				loading: false,
			}
		case UserActionTypes.USER_FAILURE:
			return {
				...state,
				error: action.error,
				loading: false,
			}
		default:
			return state;
	}
};