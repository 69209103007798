import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as uploadReducer from '../reducer/upload.reducer';


const getState = createFeatureSelector<uploadReducer.State>(
    uploadReducer.featureKey
);

export const loading = createSelector(
    getState,
    state => state.loading
);

export const files = createSelector(
    getState,
    state => state.files
);

export const progress = createSelector(
    getState,
    state => state.progress
);