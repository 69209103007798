import { Action } from '@ngrx/store';
import { Availability } from 'src/app/models';

export enum AvailabilityActionTypes {
    PARAMS = '[AVAILABILITY_PARAMS] Availability List Params',
    LIST = '[AVAILABILITY_LIST] Availability List',
    ADD = '[AVAILABILITY_ADD] Availability Add',
    UPDATE = '[AVAILABILITY_UPDATE] Availability Update',
    UPDATE_BLANK = '[AVAILABILITY_UPDATE_BLANK] Availability Update Blank',
    DELETE = '[AVAILABILITY_DELETE] Availability Detele',
    DELETE_BLANK = '[AVAILABILITY_DELETE_BLANK] Availability Detele Blank',
    FAILURE = '[AVAILABILITY_FAILURE] Availability failed',
}

export class Params implements Action {
    readonly type = AvailabilityActionTypes.PARAMS;
    constructor(public method: string, public params?: any, public params2?: any, public key?: string, public item?: any) { }
}

export class List implements Action {
    readonly type = AvailabilityActionTypes.LIST;
    constructor(public list: any) { }
}

export class Add implements Action {
    readonly type = AvailabilityActionTypes.ADD;
    constructor(public item: Availability) { }
}

export class Update implements Action {
    readonly type = AvailabilityActionTypes.UPDATE;
    constructor(public item: Availability) { }
}

export class UpdateBlank implements Action {
    readonly type = AvailabilityActionTypes.UPDATE_BLANK;
    constructor(public item: Availability) { }
}

export class Delete implements Action {
    readonly type = AvailabilityActionTypes.DELETE;
    constructor(public item: any) { }
}

export class DeleteBlank implements Action {
    readonly type = AvailabilityActionTypes.DELETE_BLANK;
    constructor(public item: any) { }
}

export class failure implements Action {
    readonly type = AvailabilityActionTypes.FAILURE;
    constructor(public error: any) { }
}


export type AvailabilityAction = Params | List | Add | Update | UpdateBlank | Delete | DeleteBlank | failure;