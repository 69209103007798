import {User} from './User';
import {Product} from "./Product";

export class Order {
  id!: number;
  user_id!: number;
  action_by!: number;
  provider_id!: number;
  type!: string;
  total!: number;
  commission!: number;
  shipping_price!: number;
  tax_rates!: number;
  coupon_id!: number;
  gift_id!: number;
  location_id!: number;
  comment!: string;
  title!: string;
  any_questions!: string;
  status!: number;
  ip_address!: string;
  transaction_id!: number;
  message_key!: number;
  created_at!: number;
  updated_at!: number | undefined;
  actionBy!: User;
  transaction!: UserTransaction;
  coupon!: UserCatalogCoupon;
  location!: UserLocation;
  user!: User;
  gift!: UserCatalogGiftCertificate;
  provider!: User;
  userCatalogOrdersHistories!: UserCatalogOrdersHistory[];
  userCatalogOrdersItems!: UserCatalogOrdersItem[];
  userCatalogOrdersItemHistories!: UserCatalogOrdersItemHistory[];
  userCatalogOrdersRecipients!: UserCatalogOrdersRecipients[];
  userCatalogOrdersStatuses!: UserCatalogOrdersStatus[];
  recipients!: User[];
  recipientIds!:any[];
  no_recipients!:number;
  is_public!:number;
  level!:string;
  slot!:string;
  role!:string;
  start_time!:string;

}

export class UserAvailabilityBooked {

}

export class UserTransaction {

}

export class UserCatalogCoupon {

}

export class UserLocation {

}

export class UserCatalogGiftCertificate {

}

export class UserCatalogOrdersHistory {

}

export class UserCatalogOrdersItem {
  id!: number;
  order_id!: number;
  item_id!: number;
  location_id!: number;
  quantity!: number;
  price!: number;
  slot!: number;
  options!: string;
  start_time!: number;
  end_time!: number;
  comment!: string;
  is_opened!: number;
  action_by!: number;
  action!: number;
  reason!: number;
  review!: any;
  status!: number;
  transaction_id!: number;
  created_at!: number;
  updated_at!: number;
  order!: Order;
  catalog!: Product;
  location!: UserLocation;
  transaction!: UserTransaction;
  actionBy!: User;
  userCatalogOrdersItemHistories!: UserCatalogOrdersItemHistory[];
  userCatalogOrdersItemReview!: UserCatalogOrdersItemReview;
}

export class UserCatalogOrdersItemHistory {

}

export class UserCatalogOrdersRecipients {

}

export class UserCatalogOrdersStatus {

}

export class UserCatalogOrdersItemReview {

}
