import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { DefaultService, GlobalService } from 'src/app/services';
import * as CmsActions from '../action/cms.actions';


@Injectable()

export class CmsEffects {
    constructor(
        private actions$: Actions,
        private defaultService: DefaultService,
        private gs: GlobalService
    ) { }

    loadSectors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.cmsSectorsInitialized),
            mergeMap(() =>
                this.defaultService.item({ moduleId: 'sector', 'per-page': 40 }).pipe(
                    map(item => CmsActions.cmsSectorsSuccess({ sectors: item.data.items })),
                    catchError(error =>
                        of(CmsActions.loadAllFailed({ error: error.message }))
                    )
                )
            )
        )
    );

    loadPractices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.cmsPracticesInitialized),
            mergeMap(() =>
                this.defaultService.item({ moduleId: 'practice', 'per-page': 40 }).pipe(
                    map(practice => CmsActions.cmsPracticeAreas({ practices: practice.data.items })),
                    catchError(error =>
                        of(CmsActions.loadAllFailed({ error: error.message }))
                    )
                )
            )
        )
    );

    loadPages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.cmsPagesInitialized),
            mergeMap(() =>
                this.defaultService.item({ moduleId: 'page', 'per-page': 40 }).pipe(
                    map(page => CmsActions.cmsPages({ pages: page.data.items })),
                    catchError(error =>
                        of(CmsActions.loadAllFailed({ error: error.message }))
                    )
                )
            )
        )
    );

    loadBlogs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.cmsBlogPodcastInitialized),
            mergeMap((payload) =>
                this.defaultService.item(payload.params).pipe(
                    map(blogs => CmsActions.cmsBlogPodcast({ blogs: blogs.data })),
                    catchError(error => {
                        this.gs.handleErrors(error);
					    return [CmsActions.loadAllFailed({ error: error.message })]
                    })
                )
            )
        )
    );

    loadNews$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.NewsInitialized),
            mergeMap((payload) =>
                this.defaultService.item(payload.params).pipe(
                    map(item => CmsActions.News({ news: item.data })),
                    catchError(error => {
                        this.gs.handleErrors(error);
					    return [CmsActions.loadAllFailed({ error: error.message })]
                    })
                )
            )
        )
    );

    loadEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.EventsInitialized),
            mergeMap((payload) =>
                this.defaultService.item(payload.params).pipe(
                    map(item => CmsActions.Events({ events: item.data })),
                    catchError(error => {
                        this.gs.handleErrors(error);
					    return [CmsActions.loadAllFailed({ error: error.message })]
                    })
                )
            )
        )
    );

    loadArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.ArticlesInitialized),
            mergeMap((payload) =>
                this.defaultService.item(payload.params).pipe(
                    map(item => CmsActions.Articles({ articles: item.data })),
                    catchError(error => {
                        this.gs.handleErrors(error);
					    return [CmsActions.loadAllFailed({ error: error.message })]
                    })
                )
            )
        )
    );

    loadLeaderships$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.LeadershipsInitialized),
            mergeMap((payload) =>
                this.defaultService.item(payload.params).pipe(
                    map(item => CmsActions.Leaderships({ leaderships: item.data })),
                    catchError(error => {
                        this.gs.handleErrors(error);
					    return [CmsActions.loadAllFailed({ error: error.message })]
                    })
                )
            )
        )
    );

    loadEducationals$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.EducationalsInitialized),
            mergeMap((payload) =>
                this.defaultService.item(payload.params).pipe(
                    map(item => CmsActions.Educationals({ educationals: item.data })),
                    catchError(error => {
                        this.gs.handleErrors(error);
					    return [CmsActions.loadAllFailed({ error: error.message })]
                    })
                )
            )
        )
    );

    loadFaqs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.FaqsInitialized),
            mergeMap((payload) =>
                this.defaultService.item(payload.params).pipe(
                    map(item => CmsActions.Faqs({ faqs: item.data })),
                    catchError(error => {
                        this.gs.handleErrors(error);
					    return [CmsActions.loadAllFailed({ error: error.message })]
                    })
                )
            )
        )
    );

    loadResearchs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.ResearchsInitialized),
            mergeMap((payload) =>
                this.defaultService.item(payload.params).pipe(
                    map(item => CmsActions.Researchs({ researchs: item.data })),
                    catchError(error => {
                        this.gs.handleErrors(error);
					    return [CmsActions.loadAllFailed({ error: error.message })]
                    })
                )
            )
        )
    );

    loadTestimonials$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CmsActions.TestimonialsInitialized),
            mergeMap((payload) =>
                this.defaultService.item(payload.params).pipe(
                    map(item => CmsActions.Testimonials({ testimonials: item.data })),
                    catchError(error => {
                        this.gs.handleErrors(error);
					    return [CmsActions.loadAllFailed({ error: error.message })]
                    })
                )
            )
        )
    );

    // showAlertOnFailure
    showAlertOnFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CmsActions.loadAllFailed),
                tap(({ error }) => window.alert(error))
            ),
        { dispatch: false }
    );

}