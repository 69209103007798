import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as productsReducer from '../reducer/products.reducer';

// Lookup the 'Joke' feature state managed by NgRx
const getState = createFeatureSelector<productsReducer.State>(
    productsReducer.featureKey
);

export const attributes = createSelector(
    getState,
    state => state.attributes
);

export const fields = createSelector(
    getState,
    state => state.fields
);

export const options = createSelector(
    getState,
    state => state.options
);

export const view = createSelector(
    getState,
    state => state.view
);

export const featuredProducts = createSelector(
  getState,
  state => state.featuredProducts
);

export const wishlist = createSelector(
  getState,
  state => state.wishlist
);

export const wishlistPagination = createSelector(
  getState,
  state => state.wishlistPagination
);


export const featuredPagination = createSelector(
  getState,
  state => state.featuredPagination
);

export const search = createSelector(
    getState,
    state => state.search
);

export const searchPagination = createSelector(
    getState,
    state => state.searchPagination
);

export const myProduct = createSelector(
    getState,
    state => state.myProducts
);

export const myPagination = createSelector(
    getState,
    state => state.myPagination
);

export const activeProducts = createSelector(
    getState,
    state => state.activeProducts
);

export const activePagination = createSelector(
    getState,
    state => state.activePagination
);

export const inactiveProducts = createSelector(
    getState,
    state => state.inactiveProducts
);

export const inactivePagination = createSelector(
    getState,
    state => state.inactivePagination
);

export const currentProducts = createSelector(
    getState,
    state => state.currentProducts
);

export const currentPagination = createSelector(
    getState,
    state => state.currentPagination
);

export const pastProducts = createSelector(
    getState,
    state => state.pastProducts
);

export const pastPagination = createSelector(
    getState,
    state => state.pastPagination
);

export const error = createSelector(
    getState,
    state => state.error
);

export const loading = createSelector(
    getState,
    state => state.loading
);
