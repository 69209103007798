import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class SocketService {

	constructor(
		private socket: Socket
	) { }

	sendMessage(message: any) {
		this.socket.emit('message', message);
	}

	getMessages() {
		return this.socket.fromEvent('message').pipe(map((data: any) => data.msg));
	}
}
