<div class="profileimage  d-flex align-items-center">
    <div class="circle">
        <!-- User Profile Image -->
        <img class="profile-pic" *ngIf="profile | async as user" [src]="user.banner ? user.banner : '/assets/images/avatar.webp'" alt="" />
        <!-- Default Image -->
        <!-- <i class="fa fa-user fa-5x"></i> -->
        <div class="loader rounded-circle" *ngIf="loading"><span></span> <span></span></div>
    </div>
    <div class="btn btn-primary">
        <img src="/assets/images/svg/camra-icon.svg" class="upload-button" alt="" for="banner_avatar"> Change Banner Photo
        <input class="file-upload" type="file" accept="image/*" (change)="upload($event)" style='width: auto;' id="banner_avatar" />
    </div>
</div>