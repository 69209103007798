export class Education {
    id!: number;
    user_id!: number;
    industry_name!: string;
    institute!: string;
    contact_email!: string;
    from!: number;
    to!: number;
    is_present: any;
    title!: string;
    description!: string;
    location!: string;
    latitude!: string;
    longitude!: string;
    status!: string;
    created_at!: number;
    updated_at!: number;
}