import { createAction, props } from '@ngrx/store';
import { Item } from 'src/app/models';

export const cmsSectorsInitialized = createAction(
    '[Item API] Cms Sectors Initialized'
);

export const cmsPracticesInitialized = createAction(
    '[Item API] Cms Practice Initialized'
);

export const cmsPagesInitialized = createAction(
    '[Item API] Cms Pages Initialized'
);

export const cmsBlogPodcastInitialized = createAction(
    '[Item API] Cms Blog Podcast Initialized',
    props<{ params: any }>()
);

export const NewsInitialized = createAction(
    '[Item API] News Initialized',
    props<{ params: any }>()
);

export const EventsInitialized = createAction(
    '[Item API] Events Initialized',
    props<{ params: any }>()
);

export const ArticlesInitialized = createAction(
    '[Item API] Articles Initialized',
    props<{ params: any }>()
);

export const LeadershipsInitialized = createAction(
    '[Item API] Leaderships Initialized',
    props<{ params: any }>()
);

export const EducationalsInitialized = createAction(
    '[Item API] Educationals Initialized',
    props<{ params: any }>()
);

export const FaqsInitialized = createAction(
    '[Item API] Faqs Initialized',
    props<{ params: any }>()
);

export const ResearchsInitialized = createAction(
    '[Item API] Researchs Initialized',
    props<{ params: any }>()
);

export const TestimonialsInitialized = createAction(
    '[Item API] Testimonials Initialized',
    props<{ params: any }>()
);

export const cmsSectorsSuccess = createAction(
    '[Item API] Cms Sectors Success',
    props<{ sectors: Item[] }>()
);

export const cmsPracticeAreas = createAction(
    '[Item API] Cms Practice Areas Success',
    props<{ practices: Item[] }>()
);

export const cmsPages = createAction(
    '[Item API] Cms Pages Success',
    props<{ pages: Item[] }>()
);

export const cmsBlogPodcast = createAction(
    '[Item API] Cms Blog Podcast Success',
    props<{ blogs: Item[] }>()
);

export const News = createAction(
    '[Item API] News Success',
    props<{ news: Item[] }>()
);

export const Events = createAction(
    '[Item API] Events Success',
    props<{ events: Item[] }>()
);

export const Articles = createAction(
    '[Item API] Articles Success',
    props<{ articles: Item[] }>()
);

export const Leaderships = createAction(
    '[Item API] Leaderships Success',
    props<{ leaderships: Item[] }>()
);

export const Educationals = createAction(
    '[Item API] Educationals Success',
    props<{ educationals: Item[] }>()
);

export const Faqs = createAction(
    '[Item API] Faqs Success',
    props<{ faqs: Item[] }>()
);

export const Researchs = createAction(
    '[Item API] Researchs Success',
    props<{ researchs: Item[] }>()
);

export const Testimonials = createAction(
    '[Item API] Testimonials Success',
    props<{ testimonials: Item[] }>()
);

export const loadAllFailed = createAction(
    '[Item API] Cms All Failed',
    props<{ error: string }>()
);