import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable({
	providedIn: 'root'
})
export class ItemService {
	isLoggedIn: Observable<boolean> = this.authService.authenticationState;
	isLoggedUser: boolean = false;

	constructor(
		private http: HttpClient,
		private gs: GlobalService,
		private authService: AuthService,

	) {
		this.isLoggedIn.subscribe(data => { this.isLoggedUser = data; });
	}


	item(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `item/index`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	categories(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'categories' : 'public-categories'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	transactions(params: any = null) {
		return this.http.request("GET", `user/transactions`, { body: null, params: params })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	profile(method = "GET", params: any = null, params2: any = null) {
		let action = this.isLoggedUser ? `user/profile` : `user/public-profile`;
		return this.http.request(method, action, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	is_available(params2: any = null) {
		return this.http.request("POST", 'user/is-available', { body: null, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	survey(method = "GET", params = null, params2: any = null) {
		let action = `user/survey`;
		return this.http.request(method, action, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	changePassword(credentials: any) {
		return this.http.post('user/change-password', credentials)
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	education(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/education`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	experience(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/experience`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	award(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/award`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	expertise(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/index`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	availability(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/availability`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	unavailability(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/unavailability`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	membership(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/membership`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	zipcode(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/zipcode`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	location(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/${this.isLoggedUser ? 'location' : 'public-location'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	products(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'index' : 'public-index'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	productsFields(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/products-fields`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	productsAttributes(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/products-attributes`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	productsOptions(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'products-options' : 'public-products-options'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	productsModels(params2: any = null) {
		return this.http.request("GET", `user-catalog/product-models`, { params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	wishlist(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/wishlist`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	brands(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'brands' : 'public-brands'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	shippingRates(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'shipping-rates' : 'public-shipping-rates'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	weightUnit(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/weight-unit`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	lengthUnit(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/length-unit`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	giftCertificate(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/gift-certificate`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	catalog(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/catalog`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	services(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'services' : 'index'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	cart(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/cart`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	bulk(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/bulk`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	favorite(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/favorite`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	search(params2: any = null) {
		//params2.category_id = params2.category_id.length ? params2.category_id.join() : '';
		//params2.skills = params2.skills.length ? params2.skills.join() : '';
		return this.http.request("GET", `user/${this.isLoggedUser ? 'search' : 'public-search'}`, { body: null, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	orders(method = "GET", params = null, params2: any = null) {
		let action = `user-catalog/${this.isLoggedUser ? 'orders' : 'public-orders'}`
		return this.http.request(method, action, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	booking(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/booking`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	review(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'review' : 'public-review'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	orderComment(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/order-comment`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	coupons(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/coupons`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	meeting(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/meeting`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	contribution(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/contribution`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	milestone(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/milestone`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	skills(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/public-skill`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	stripe(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/stripe`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

}
