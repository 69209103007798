
<!--  
<pre>
    {{moment(currentDate).format('lll')}} <br>
    {{currentDate | date: 'full'}}
</pre>  -->

<app-loader></app-loader>
<app-toasts></app-toasts>
<app-confirm-dialog></app-confirm-dialog>
<!-- <button (click)="sendMsg()">Send Msg</button> -->
<!-- <fb-like href="https://github.com/zyra/ngx-facebook"></fb-like> -->
<router-outlet></router-outlet>
