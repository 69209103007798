import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/models';
import { GlobalService } from 'src/app/services/global.service';
import { ItemService } from 'src/app/services/item.service';
import { OrdersAction } from '../action';

@Injectable()
export class OrdersEffects {
	constructor(
		private actions$: Actions,
		private itemService: ItemService,
		private gs: GlobalService,
	) {

	}

	orderParams$ = createEffect(() => this.actions$.pipe(
		ofType(OrdersAction.OrdersActionTypes.ORDERS_MY_LIST_PARAMS),
		mergeMap((params: OrdersAction.MyOrderParams) =>
			this.itemService.orders(params.method, params?.params, params?.params2).pipe(
				map((response: any) => {
					const key = params?.key;
					let data = this.gs.apiResponce(response);
					let actionResponse = null;
					switch (key) {
						case 'view':
							actionResponse = new OrdersAction.View(data.data);
							break;

						case 'my-orders':
							actionResponse = new OrdersAction.MyOrders(data.data);
							break;

						case 'my-orders-more':
							actionResponse = new OrdersAction.MyOrdersMore(data.data ? data.items : []);
							break;

						case 'completed':
							actionResponse = new OrdersAction.CompletedList(data.data);
							break;

						case 'completed-more':
							actionResponse = new OrdersAction.CompletedListMore(data.data ? data.items : []);
							break;

						case 'cancelled':
							actionResponse = new OrdersAction.CancelledAuctions(data.data);
							break;

						case 'cancelled-more':
							actionResponse = new OrdersAction.CancelledAuctionsMore(data.data ? data.items : []);
							break;

						case 'create_order':
							this.gs.alert('You have successfully created offers.', 'success');
							this.gs.router('/user/my-auctions');
							actionResponse = new OrdersAction.Add(data.data, 'create_order');
							break;

						case 'update_order':
							this.gs.alert('You have successfully updated offers.', 'success');
							this.gs.router('/user/my-auctions');
							actionResponse = new OrdersAction.Update(data.data, 'update_order');
							break;

						default:
							actionResponse = new OrdersAction.MyOrders(data.data);
							break;
					}

					return actionResponse;

				}),
				catchError((error) => {
					this.gs.handleErrors(error);
					return [new OrdersAction.failure(error)]
				})
			))
	));


	orderSearch$ = createEffect(() => this.actions$.pipe(
		ofType(OrdersAction.OrdersActionTypes.ORDERS_SEARCH_PARAMS),
		mergeMap((options: OrdersAction.OrderSearchParams) =>
			this.itemService.orders("GET", null, options.params).pipe(
				map((response: ApiResponse) => {
					let data = this.gs.apiResponce(response);
					return new OrdersAction.OrderSearch(data?.items ? data.items : [], data.pagination ? data.pagination : null);
				}),
				catchError((error) => {
					this.gs.handleErrors(error);
					return [new OrdersAction.failure(error)]
				})
			))
	));

}


