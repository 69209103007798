import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as CertificationReducer from '../reducer/certification.reducer';

const getState = createFeatureSelector<CertificationReducer.State>(
    CertificationReducer.featureKey
);

export const certification = createSelector(
    getState,
    state => state.certification
);

export const loading = createSelector(
    getState,
    state => state.loading
);