import { Pagination } from 'src/app/models';
import { EducationsAction, EducationsActionTypes } from '../action/educations.actions';

export const featureKey = 'educations';

export interface State {
	educations: any,
	pagination: Pagination | null,
	loading: boolean,
	error: any
}

export const initialState: State = {
	educations: null,
	pagination: null,
	loading: false,
	error: ''
};


export function reducer(state = initialState, action: EducationsAction | any): State {
	switch (action.type) {
		case EducationsActionTypes.PARAMS:
			return {
				...state,
				loading: true,
				error: '',
			}
		case EducationsActionTypes.LIST:
			return {
				...state,
				loading: false,
				educations: action.list ? action.list.items : [],
				pagination: action.list ? action.list.pagination : null
			}
		case EducationsActionTypes.ADD:
			return {
				...state,
				educations: [action.item, ...state.educations],
				loading: false
			}
		case EducationsActionTypes.UPDATE:
			const index = state.educations.findIndex((f: any) => f.id === action.item.id);
			return {
				...state,
				educations: [...state.educations.slice(0, index),
				action.item,
				...state.educations.slice(index + 1)
				],
				loading: false
			}
		case EducationsActionTypes.DELETE:
			return {
				educations: [...state.educations.filter( (val: any) => val.id !== action.item.id )],
				pagination: state.pagination,
				loading: false,
				error: '',
			}
		case EducationsActionTypes.FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			}
		default:
			return state;
	}
}